import React, { useState, useEffect } from "react";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { t } from "i18next";
import { getAllCDFs } from "../../collects-service";
import { useAuth } from "../../../../contexts/auth-context";
import { useLocation, useParams } from "react-router-dom";
import { CDFDTO } from "./modal-cdf/cdf-dto";
import Tooltip from '../../../../components/tooltip/tooltip';
import { formatTimestampDate } from "../../../../services/date-format";
interface CDFSectionProps {
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  hovered: string;
  setMtrID: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCDF: React.Dispatch<React.SetStateAction<CDFDTO | undefined>>;
  setCdfModal: React.Dispatch<React.SetStateAction<boolean>>;
  cdfClosed: boolean;
  setCdfClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

const CDFSection: React.FC<CDFSectionProps> = ({
  setHovered,
  hovered,
  setMtrID,
  setSelectedCDF,
  setCdfModal,
  cdfClosed,
  setCdfClosed
}) => {
  const [cdfs, setCDFs] = useState<CDFDTO[]>([]);
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const fetchCDFs = async () => {
    try {
      let generatorId;
      let dealerId;
      if (profile == "scrap-generator") {
        generatorId = originId;
      } else if (id && location.pathname.includes("collections-dealer")) {
        generatorId = undefined;
        dealerId = id
      } else if (id && location.pathname.includes("collections-generator")) {
        generatorId = id;
        dealerId = undefined;
      }

      const data = await getAllCDFs(profile ?? "", generatorId, dealerId);
      setCDFs(data);
    } catch (error) {
      console.error("Error fetching CDFs:", error);
    }
  };

  useEffect(() => {
    fetchCDFs();
  }, []);

  useEffect(() => {
    if (cdfClosed) {
      fetchCDFs();
      setCdfClosed(false);
    }
  }, [cdfClosed, setCdfClosed]);

  const cdfTableHeader = [
    t("label-number"),
    t("label-date"),
    "MTR",
    t("label-residue"),
  ];

  const cellStyle = {
    padding: "10px",
    textAlign: "center",
    verticalAlign: "middle",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  return (
    <div className="info-section">
      <SparkTable>
        <SparkTableHead>
          <SparkTableRow>
            {cdfTableHeader.map((header, index) => (
              <SparkTableCell key={index} style={cellStyle}>
                <strong>{header.toUpperCase()}</strong>
              </SparkTableCell>
            ))}
          </SparkTableRow>
        </SparkTableHead>
        <SparkTableBody>
          {cdfs.map((cdfItem: CDFDTO) => (
            <SparkTableRow
              key={cdfItem.cdfNumber}
              onMouseEnter={() => setHovered(cdfItem.cdfNumber)}
              onMouseLeave={() => setHovered("")}
              onClick={() => {
                setSelectedCDF(cdfItem);
                setMtrID(cdfItem.mtr);
                setCdfModal(true);
              }}
            >
              <SparkTableCell style={cellStyle}>
                {cdfItem.cdfNumber}
              </SparkTableCell>
              <SparkTableCell style={cellStyle}>
                {formatTimestampDate(cdfItem.date)}
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.6" }}>{cdfItem.mtr}</SparkTableCell>
              <SparkTableCell style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
                maxWidth: "150px",

                whiteSpace: "normal", flex: "1.4"
              }}>
                <Tooltip
                  text={
                    cdfItem.isHazardousMaterial
                      ? t("label-hazardous-material")
                      : t("label-non-hazardous-material")
                  }
                >
                  <div>
                    {cdfItem.isHazardousMaterial && (
                       <div   style={{ marginRight: "110px" }}> 
                        
                        <SparkIcon
                       
                          className="icon-flag"
                          no-padding={true}
                          icName={"danger-hazards"}
                        />
                      </div>
                    )}
                    <p >
                      {cdfItem.material.name}
                    </p>
                  </div>
                </Tooltip>
              </SparkTableCell>
            </SparkTableRow>
          ))}
        </SparkTableBody>
      </SparkTable>
    </div>
  );
};

export default CDFSection;
