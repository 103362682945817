import {
  collection,
  doc,
  getAggregateFromServer,
  getDoc,
  getDocs,
  query,
  sum,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  saveNewAsync,
  saveNewWithId,
  update,
  callFunction,
  QueryOptions,
} from "../../services/persistence";
import { CategoryTotalDTO } from "./scrap-category/models/scrap-category-total-dto";
import {
  ObservationsDTO,
  ScrapGeneratorDTO,
} from "../scrap-generators/scrap-generators-DTO";
import { savePendingApproval } from "../../services/pending-approval-service";
import { ScrapInfoDTO } from "./scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import {
  MTRCategoryClassDTO,
  MTRCategoryDTO,
  PhisicalState,
  ScrapsCategoryDTO,
  ScrapsCategoryGroupDTO,
  ScrapsCategoryInfoDTO,
  Unit,
} from "./scrap-category/models/scrap-category-DTO";

// add new
export async function addNewScrapCategory(
  data: ScrapsCategoryDTO,
  profile?: string,
  userId?: string
) {
  if (data.id == undefined) {
    if (profile && profile != "admin-bosch") {
      if (userId) {
        await savePendingApproval(data, userId, "SCRAP_CATEGORY");
      }
    } else {
      saveNewAsync(COLLECTIONS.SCRAP_CATEGORIES, data.scrapsCategoryInfo).then(
        (docRef) => {
          saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
        }
      );
    }
  } else {
    update(COLLECTIONS.SCRAP_CATEGORIES, data.scrapsCategoryInfo, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    callFunction(
      "updatescrapcategoryname",
      {
        scrapCategoryId: data.id,
        scrapCategoryName: data.scrapsCategoryInfo.name,
      },
      data.id
    );
  }
}

export async function approveOrReproveScrapCategory(
  data: ScrapsCategoryInfoDTO
) {
  update(COLLECTIONS.SCRAP_CATEGORIES, data, data.id);
}

export async function getScrapCategoriesApproved(): Promise<
  ScrapsCategoryInfoDTO[]
> {
  const scrapCategoriesRef = collection(
    firestore,
    COLLECTIONS.SCRAP_CATEGORIES
  );

  const querySnapshot = await getDocs(scrapCategoriesRef);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["name"].concat(
        " ",
        doc.data()["description"],
        " ",
        doc.data()["composition"],
        " ",
        doc.data()["materialClass"]
      ),
  })) as unknown as ScrapsCategoryInfoDTO[];

  return items;
}

export async function getScrapCategoriesApprovedFilteredByGroup(id: string): Promise<
  ScrapsCategoryInfoDTO[]
> {
  const scrapCategoriesRef = collection(
    firestore,
    COLLECTIONS.SCRAP_CATEGORIES
  );

  const q = query(
    scrapCategoriesRef,
    where("group.id", "==", id)
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["name"].concat(
        " ",
        doc.data()["description"],
        " ",
        doc.data()["composition"],
        " ",
        doc.data()["materialClass"]
      ),
  })) as unknown as ScrapsCategoryInfoDTO[];

  return items;
}

export async function getScrapGroups(): Promise<ScrapsCategoryGroupDTO[]> {
  const scrapCategoriesRef = collection(firestore, COLLECTIONS.SCRAP_GROUPS);

  const querySnapshot = await getDocs(scrapCategoriesRef);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc.data()["scrapGroup"].concat(),
  })) as unknown as ScrapsCategoryGroupDTO[];

  return items;
}

export const getScrapCategoryById = async (
  id: string
): Promise<ScrapsCategoryDTO | null> => {
  const scrapsCategoryInfo = await getScrapCategory(id);
  const observations = await getScrapCategoryObservation(id);

  return {
    id,
    scrapsCategoryInfo,
    observations,
  };
};

export const getScrapGroupById = async (
  id: string
): Promise<ScrapsCategoryGroupDTO | null> => {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_GROUPS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapsCategoryGroupDTO;
};

export async function getScrapCategoryObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
export async function getScrapCategory(
  id: string
): Promise<ScrapsCategoryInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_CATEGORIES, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapsCategoryInfoDTO;
}

export async function getScrapCategoryTotalbyId(
  id: string
): Promise<CategoryTotalDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_CATEGORY_TOTAL, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CategoryTotalDTO;
}

export async function sumScrapCategoryFee(
  scrapCategory: string
): Promise<number> {
  const coll = collection(firestore, COLLECTIONS.FINANCIAL);
  const q = query(
    coll,
    where("scrapMaterialName", "==", scrapCategory)
  );
  const snapshot = await getAggregateFromServer(q, {
    totalFee: sum('valueFee')
  });
  
  return snapshot.data().totalFee;
}

export async function getScrapCategoryTotal(): Promise<any> {
  const docRef = collection(firestore, COLLECTIONS.SCRAP_CATEGORY_TOTAL);
  const docSnap = await getDocs(docRef);
  const itens = docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return itens as any;
}

export async function getScrapByGenerator(
  id: string
): Promise<ScrapGeneratorDTO[]> {
  const scrapByGeneratorCollectionRef = collection(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR
  );
  const q = query(
    scrapByGeneratorCollectionRef,
    where("scrapCategory.id", "==", id)
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ScrapGeneratorDTO[];
  return items;
}

export async function getScrapMaterials(
  options?: QueryOptions,
  onlyContracted?: boolean
): Promise<ScrapInfoDTO[]> {
  const scrapByGeneratorCollectionRef = collection(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR
  );

  let q = query(scrapByGeneratorCollectionRef);

  if (onlyContracted) {
    q = query(q, where("status", "==", "OPERATING"));
  }

  if (options) {
    const { fieldName, operator, value } = options;
    q = query(q, where(fieldName, operator, value));
  }

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as ScrapInfoDTO[];

  return items;
}

export async function getScrapMaterialContractedById(
  id: string
): Promise<ScrapInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapInfoDTO;
}

export async function 
getMTRCategoriesApproved(): Promise<MTRCategoryDTO[]> {
  const docRef = collection(firestore, COLLECTIONS.MTR_CATEGORY);
  const docSnap = await getDocs(docRef);
  const itens = docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  return itens as MTRCategoryDTO[];
}

export async function getMTRCategoriesClassApproved(): Promise<
  MTRCategoryClassDTO[]
> {
  const docRef = collection(firestore, COLLECTIONS.MTR_CATEGORY_CLASS);
  const docSnap = await getDocs(docRef);
  const itens = docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  return itens as MTRCategoryClassDTO[];
}

export async function getMTRPhisicalState(): Promise<PhisicalState[]> {
  const docRef = collection(firestore, COLLECTIONS.MTR_PHISIC_STATE);
  const docSnap = await getDocs(docRef);
  const itens = docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  return itens as PhisicalState[];
}

export async function getMTRUnit(): Promise<Unit[]> {
  const docRef = collection(firestore, COLLECTIONS.MTR_UNITY);
  const docSnap = await getDocs(docRef);
  const itens = docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  return itens as Unit[];
}
