import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { CreditAnalysisDTO } from "../credit-analysis-DTO";
import { addNew, getById } from "../credit-analysis-service";
import { useAlert } from "../../../../../contexts/alert-context";
import { creditAnalysisStausENUM } from "../../../../../assets/enums/credit-analysis-status-enum";
import Expiration from "../../../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import { CurrencyInput } from "react-currency-mask";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../services/address-service";

interface ModalProps {
  onClose: () => void;
  contactId: string;
}

const CreditAnalysisForm: React.FC<ModalProps> = ({ onClose, contactId }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [limitValueDefaultValue, setLimitValueDefaultValue] = useState("");
  const [localeEntity, setlocaleEntity] = useState("");
  const [currencyEntity, setcurrencyEntity] = useState("");
  const [currencyNameEntity, setCurrencyNameEntity] = useState("");
  const [currencyFormatting, setcurrencyFormatting] = useState("");

  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Credit Analysis",
    expirationDate: "",
    warningPeriod: 0,
    solved: false,
    hasExpirationDate: false,
    origin: ""
  });

  const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CreditAnalysisDTO>();

  if (id) {
    getAdrressByEntityId(id).then((data) => {
      if (data) {
        getCountryConfigById(data.country).then((data) => {
          if (data) {
            setcurrencyEntity(data.currency);
            setlocaleEntity(data.locale);
            setCurrencyNameEntity(data.currencyName)
            setcurrencyFormatting(data.currencyFormatting)
          }
        });
      }
    });
  }

  useEffect(() => {
    if (contactId) {
      getById(contactId).then((data) => {
        if (data) {
          setLimitValueDefaultValue(data.creditAnalysisInfoDTO.limitValue);
          setExpiration({ ...data.creditAnalysisInfoDTO.expirationDocument})

          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof CreditAnalysisDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof CreditAnalysisDTO, value as any);
            }
          });
      
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (document: CreditAnalysisDTO) => {
    try {
      document.creditAnalysisInfoDTO.currencyName = currencyNameEntity;
      document.creditAnalysisInfoDTO.currencySymbol = currencyEntity;

      if (expiration.hasExpirationDate) {
        if (expiration.expirationDate.length === 0) {
          return setAlert({ text: `${t("alert-error-date-empty")}`, type: "error" });
        } else {
          document.creditAnalysisInfoDTO.expirationDocument = expiration;
        }
      }
      if (id) {
        await addNew(document, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-date-empty")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-credit-analysis")}</h2>
          <div className="flex-column">
            <label htmlFor="limitValue">{t("label-credit-limit-value")} <span style={{marginLeft:5, color:"#9e9e9e"}}>{`(${currencyNameEntity})`}</span></label>

            <CurrencyInput
              currency={currencyEntity}
              locale={localeEntity}
              defaultValue={limitValueDefaultValue}
              onChangeValue={(event, originalValue, maskedValue) => {
                setValue(
                  "creditAnalysisInfoDTO.limitValue",
                  maskedValue,
                  {
                    shouldValidate: true,
                  }
                );
              }}
              InputElement={
                <input
                  placeholder={t("placeholder.generalEmpty")}
                  type="text"
                  id="limitValue"
                  {...register("creditAnalysisInfoDTO.limitValue", {
                    required: true,
                  })}
                />
              }
            />
            {errors.creditAnalysisInfoDTO?.limitValue && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{t("label-score")}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("creditAnalysisInfoDTO.score", {
                required: true,
              })}
            />
            {errors.creditAnalysisInfoDTO?.score && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="accountType">{t("label-status")}</label>
            <select
              id="accountType"
              {...register("creditAnalysisInfoDTO.status", { required: true })}
            >
              <option value=""></option>
              {Object.entries(creditAnalysisStausENUM).map(([key, value]) => (
                <option key={key} value={key}>
                  {t(`creditAnalysisStausENUM.${value}`)}
                </option>
              ))}
            </select>
            {errors.creditAnalysisInfoDTO?.status && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
        </div>
        <br />

        {
          (!contactId || expiration.originId != "") && <Expiration
            dto={expiration}
            showPreviousWarning
            dataHandler={handleDataChange}
            expirationDate={"expirationDate"}
            warningPeriod={"warningPeriod"}
            hasExpirationDate={"hasExpirationDate"}
            hasExpirationDateInit={expiration.hasExpirationDate || false}
            expirationDateInit={expiration.expirationDate || ""}
            warningPeriodInit={expiration.warningPeriod || 0}
          />
        }


        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations" style={{ paddingBottom: 10 }}>
              {t("label-general-observations")}
            </label>

            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreditAnalysisForm;
