import React, { useEffect, useState, useCallback } from "react";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { CADRItemDTO } from "../../models/cadri-item-DTO";
import { t } from "i18next";
import { getAllCadris } from "../../cadri-service";
import NoData from "../../../../components/NoData/NoData";
import { receiveNumberFormatWithMilhar } from "../../../../services/number-format";
import { useLocation, useParams } from "react-router-dom";
import Tooltip from '../../../../components/tooltip/tooltip';
interface CADRISectionProps {
  seeCadriDetailsModal: (cadri: CADRItemDTO) => void;
  originId: string;
  profile: string;
  observer: any;
}

const CADRISection: React.FC<CADRISectionProps> = ({
  seeCadriDetailsModal,
  originId,
  profile,
  observer
}) => {
  const [cadriData, setCadriData] = useState<CADRItemDTO[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const fetchCadris = useCallback(async () => {
    try {
      let data: CADRItemDTO[];

      if (profile === "scrap-generator") {
        data = await getAllCadris({
          fieldName: "generatorId",
          operator: "==",
          value: originId,
        });
      } else if (location.pathname.includes("collections-generator")) {
        data = await getAllCadris({
          fieldName: "generatorId",
          operator: "==",
          value: id,
        });
      } else if (location.pathname.includes("collections-dealer")) {
        data = await getAllCadris({
          fieldName: "contractedDealerId",
          operator: "==",
          value: id,
        });
      } else {
        data = await getAllCadris();
      }

      setCadriData(data);
    } catch (error) {
      console.error("Error fetching CADRI data:", error);
      setError("Failed to load CADRI data.");
    }
  }, [profile, originId]);

  useEffect(() => {
    fetchCadris();
  }, [fetchCadris, observer]);

  const cadriTableHeader = [
    t("label-material"),
    t("label-generator"),
    t("label-scrap-dealer"),
    t("label-approved"),
    t("label-collected"),
    t("label-balance"),
  ];

  const cellStyle = {
    padding: "10px",
    textAlign: "center",
    verticalAlign: "middle",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (cadriData.length === 0) {
    return <NoData />;
  }

  return (
    <div className="info-section">
      <SparkTable>
        <SparkTableHead>
          <SparkTableRow>
            {cadriTableHeader.map((header, index) => (
              <SparkTableCell key={index} style={cellStyle}>
                <strong>{header.toUpperCase()}</strong>
              </SparkTableCell>
            ))}
          </SparkTableRow>
        </SparkTableHead>
        <SparkTableBody>
          {cadriData.map((rowItem, rowIndex) => (
            <SparkTableRow
              key={rowIndex}
              onClick={() => seeCadriDetailsModal(rowItem)}
            >
              <SparkTableCell style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
                maxWidth: "130px",
                whiteSpace: "normal", 
                flex: "1.0"
              }}>
                <Tooltip
                  text={
                    rowItem.isHazardousMaterial
                      ? t("label-hazardous-material")
                      : t("label-non-hazardous-material")
                  }
                >
                <div>
                    {rowItem.isHazardousMaterial && (
                       <div   style={{ marginRight: "110px" }}> 
                        
                        <SparkIcon
                       
                          className="icon-flag"
                          no-padding={true}
                          icName={"danger-hazards"}
                        />
                      </div>
                    )}
                    <p >
                      {rowItem.scrapMaterialName}
                    </p>
                  </div>
                </Tooltip>
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.2" }}>
                {rowItem.scrapGeneratorName}
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.5" }}>
                {rowItem.scrapDealerName}
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.2" }}>
                {rowItem.quantityApproved
                  ? receiveNumberFormatWithMilhar(rowItem.quantityApproved)
                  : "Aguardando"}
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.2" }}></SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.2" }}></SparkTableCell>
            </SparkTableRow>
          ))}
        </SparkTableBody>
      </SparkTable>
    </div>
  );
};

export default CADRISection;
