import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAlert } from "../../../../contexts/alert-context";
import { useForm } from "react-hook-form";
import {
  ContactsDTO,

} from "../../../contacts/contacts-DTO";
import {
  formatTimestampDate,
  formatTimestampDateAndTime,
  formatTimestampToDate,
} from "../../../../services/date-format";
import { setExpirationDocument } from "../../../../components/Expiration/ExpirationService";
import { ExpirationDTO } from "../../../../components/Expiration/models/ExpirationDTO";

interface ModalProps {
  onClose: () => void;
  contactId: string;
  item: any;
}

const ExpirationDocumentSolveForm: React.FC<ModalProps> = ({
  onClose,
  contactId,
  item,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [selectedType, setSelectedType] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ExpirationDTO>();

  const handleTypeChange = () => {
    setSelectedType(!selectedType);
  };

  useEffect(() => {
    setValue("solved", selectedType);
  }, [selectedType]);

  useEffect(() => {
    if (item) {
      const formattedDate = formatTimestampToDate(item.startWarningDate);
      setSelectedType(item.solved);
      reset({
        ...item,
        startWarningDateLabel: formattedDate,
      });
    }
  }, [item]);

  const onSubmit = async (document: any) => {
    try {
      setExpirationDocument(document, document.id);
      onClose();
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-date-empty")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-expiration")}</h2>

          <div className="flex-column">
            <label htmlFor="docName">{t("label-document")}</label>
            <input
              disabled
              type="text"
              id="docName"
              {...register("docName", { required: true })}
            />
          </div>
          <div className="flex-column">
            <label htmlFor="origin">{t("referent-to")}</label>
            <input
              disabled
              type="text"
              id="origin"
              {...register("origin", { required: true })}
            />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label htmlFor="origin">{t("label-expiration")}</label>
              <input
                disabled
                type="date"
                id="expirationDate"
                {...register("expirationDate", { required: true })}
              />
            </div>
            <div className="flex-column">
              <label htmlFor="origin">{t("start-warning")}</label>
              <input
                disabled
                type="date"
                id="startWarningDateLabel"
                {...register("startWarningDateLabel", { required: true })}
              />
            </div>
          </div>

          <div className="flex-row align-center" style={{ gap: "5px" }}>
            <input
              id="solvedCheckbox"
              type="checkbox"
              checked={selectedType === true}
              onChange={handleTypeChange}
            />
            <label htmlFor="solvedCheckbox">{t("label-solved")}</label>
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ExpirationDocumentSolveForm;
