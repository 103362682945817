import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase-config";
import { getVehiclesByGeneratorAndDealer } from "../../pages/vehicles/vehicles-service";

const VehiclesInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  scrapData: any;
  onChange: (vehicle: any) => void;
  initialValue?: any;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  scrapData,
  onChange,
  initialValue,
  isDisabled,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [vehicleOptions, setVehicleOptions] = useState<any[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<any>(
      initialValue || null
    );
    const [inputValue, setInputValue] = useState("");
    const [userId, setUserId] = useState<string>();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserId(user.uid);
        } else {
          setUserId("");
        }
      });
      return () => unsubscribe();
    }, []);

    const handleChangeRef = React.useRef(onChange);
    handleChangeRef.current = onChange;

    useEffect(() => {
      const fetchData = async () => {
        try {
          const vehicles = await getVehiclesByGeneratorAndDealer(
            scrapData.contractDealer,
            scrapData.originId
          );

          const options = vehicles.map((vehicle) => ({
            value: vehicle.id,
            label: `${vehicle.type} - ${vehicle.licensePlate}`,
            data: vehicle,
          }));

          setVehicleOptions(options);

          if (initialValue) {
            const initialVehicle = options.find(
              (option) => option.value === initialValue.id
            );
            if (initialVehicle) {
              setSelectedVehicle(initialVehicle.data);
              handleChangeRef.current(initialVehicle.data);
            }
          }
        } catch (error) {
          console.error("Error fetching vehicles:", error);
        }
      };

      fetchData();
    }, [scrapData]);

    const handleChange = (selectedOption: any) => {
      if (selectedOption && selectedOption.data) {
        setSelectedVehicle(selectedOption.data);
        handleChangeRef.current(selectedOption.data);
      } else {
        setSelectedVehicle(null);
        handleChangeRef.current(null);
      }
    };

    const handleInputChange = (newValue: string) => {
      setInputValue(newValue);
    };

    const customFilterOptions = (candidate: any, input: string) => {
      return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
    };

    const customStyles: StylesConfig<any, false> = {
      option: (provided) => ({
        ...provided,
      }),
    };

    return (
      <div className="flex-column select-field">
        <div className="flex-row justify-between">
          <label htmlFor="vehicle">{label}</label>
        </div>
        <div>
          <Select
            id="vehicle"
            placeholder={placeholder}
            value={
              selectedVehicle
                ? { value: selectedVehicle.id, label: `${selectedVehicle.type} - ${selectedVehicle.licensePlate}` }
                : null
            }
            onChange={handleChange}
            onInputChange={(newValue, { action }) => {
              if (action === "input-change") {
                handleInputChange(newValue);
              }
            }}
            options={vehicleOptions}
            filterOption={customFilterOptions}
            isDisabled={isDisabled}
            styles={customStyles}
          />
        </div>
      </div>
    );
  };

export default VehiclesInputAutocomplete;
