import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrapGeneratorDTO } from "../../../../scrap-generators-DTO";
import { getScrapGeneratorById } from "../../../../scrap-generator-service";
import { useAuth } from "../../../../../../contexts/auth-context";

const ScrapGeneratorDetailsRegister: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapGenerator, setScrapGenerator] =
    useState<ScrapGeneratorDTO | null>(null);

  useEffect(() => {
    const fetchScrapGenerator = async () => {
      if (!id) return;
      try {
        const data = await getScrapGeneratorById(id);
        if (data) {
          setScrapGenerator(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap Generator:", error);
      }
    };

    fetchScrapGenerator();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="register-tab">
        <div className="flex-row edit-section">
          <a
            onClick={() =>
              navigate(`/scrap-generators-edit/${scrapGenerator?.id}`)
            }
          >
            {t("label-edit")}
          </a>
        </div>

        {scrapGenerator?.scrapGeneratorInfo.headquarterId && (
          <div className="info-section">
            <div className="flex-row title">
              <h3>{t("label-headquarter")}</h3>
              <a
                onClick={() =>
                  navigate(
                    `/scrap-generators-details/${scrapGenerator?.scrapGeneratorInfo.headquarterId}/register`
                  )
                }
              >
                {t("label-view")}
              </a>
            </div>
            <div className="flex-column">
              <label>{t("label-headquarter-name")}</label>
              <input
                value={
                  scrapGenerator?.scrapGeneratorInfo.headquarterName || "-"
                }
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className="info-section">
          <h3>{t("label-business-information")}</h3>
          <div className="flex-column">
            <label>{t("label-legal-name")}</label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.legalName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-trading-name")}</label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.tradingName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>
              {scrapGenerator?.scrapGeneratorInfo.nationalRegisterLabel ||
                t("label-national-register")}
            </label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.nationalRegister || "-"}
              disabled={true}
            />
          </div>
          {scrapGenerator?.addressInfo?.countryName === 'Brasil'&& (
            <div className="flex-column">
              <label>{t("label-business-mtr-code")}</label>
              <input
                value={scrapGenerator?.scrapGeneratorInfo.mtrCode || "-"}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className="info-section">
          <h3>{t("label-location")}</h3>
          <div className="flex-column">
            <label>
              {scrapGenerator?.addressInfo.postalCodeLabel ||
                t("label-postal-code")}
            </label>
            <input
              value={scrapGenerator?.addressInfo.postalCode || "-"}
              disabled={true}
            />
          </div>

          <div className="flex-column">
            <label>{t("label-address")}</label>
            <input
              value={scrapGenerator?.addressInfo.address || "-"}
              disabled={true}
            />
          </div>

          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-city")}</label>
              <input
                value={scrapGenerator?.addressInfo.city || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-state")}</label>
              <input
                value={scrapGenerator?.addressInfo.state || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-contact")}</h3>
          <div className="flex-column">
            <label>{t("label-contact-name")}</label>
            <input
              value={scrapGenerator?.contactInfo.name || "-"}
              disabled={true}
            />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-email")}</label>
              <input
                value={scrapGenerator?.contactInfo.email || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-phone")}</label>
              <input
                value={scrapGenerator?.contactInfo.phone || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-function")}</label>
              <input
                value={scrapGenerator?.contactInfo.function || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-bank-details")}</h3>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-bank")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.bank || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-account-type")}</label>
              <input
                value={
                  t(
                    `accountTypesENUM.${scrapGenerator?.bankAccountInfo.accountType}_ACCOUNT`
                  ) || "-"
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="triple-fields">
            <div className="flex-column">
              <label>{t("label-account-number")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.accountNumber || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-digit")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.digit || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-agency")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.agency || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-general-observations")}</h3>
          <div>
            <textarea
              disabled
              value={scrapGenerator?.observations.observations}
            ></textarea>
          </div>
        </div>
        <div className="flex-row buttons-section">
          <button
            onClick={() => {
              navigate("/scrap-generators");
            }}
            className="neutral-white"
          >
            {t("label-back")}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorDetailsRegister;
