import React, { useState, useEffect } from "react";
import { t } from "i18next";
import {
  callFunction,
  update,
  updateAsync,
  updateOnlyFieldOfForm,
} from "../../../../../services/persistence";
import { useAlert } from "../../../../../contexts/alert-context";
import { COLLECTIONS } from "../../../../../assets/enums/firebase-colections";
import { useAuth } from "../../../../../contexts/auth-context";
import { arrayUnion } from "firebase/firestore";
import CTAInfoComponent from "../../../../../components/CTAInfoComponent/CTAInfoComponent";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { Trans } from "react-i18next";
import { sendEmailSDM } from "../../../../../services/send-email-SDM";
import { addNewCadri } from "../../../../collects/cadri-service";
import { CADRItemDTO } from "../../../../collects/models/cadri-item-DTO";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import { formatDateToStringYearFirst } from "../../../../../services/date-format";
import { setExpirationDocument } from "../../../../../components/Expiration/ExpirationService";
import { generateEmailTemplate } from "../../../../../assets/templates/email-template";
import i18n from "../../../../../assets/i18n/i18n";
import { useParams } from "react-router-dom";
import { ScrapDTO } from "../../../scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { getScrapAdminById } from "../../../scrap-admin-service";
import { StatusEnum } from "../../../../../assets/enums/status-enum";

const TokenInput: React.FC<{
  onComplete: (arg: string) => void;
  value: string;
}> = ({ onComplete, value }) => {
  const [values, setValues] = useState(Array(6).fill(""));

  useEffect(() => {
    let subValue = Array(6).fill("");
    for (let i = 0; i < value.length; i++) {
      subValue[i] = value[i];
    }
    setValues(subValue);
  }, [value]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    const newValues = [...values];

    if (newValue.length === 1) {
      newValues[index] = newValue;
      setValues(newValues);

      if (index < 5) {
        (
          document.getElementById(`digit-${index + 1}`) as HTMLInputElement
        )?.focus();
      }

      if (newValues.every((val) => val !== "")) {
        onComplete(newValues.join(""));
      }
    } else if (newValue === "") {
      newValues[index] = "";
      setValues(newValues);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      const previousInput = document.getElementById(
        `digit-${index - 1}`
      ) as HTMLInputElement;
      previousInput.focus();
      previousInput.setSelectionRange(
        previousInput.value.length,
        previousInput.value.length
      );
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text").trim().replace(" ", "");
    if (pasteData.length === 6) {
      const newValues = pasteData.split("").slice(0, 6);
      setValues(newValues);
      onComplete(newValues.join(""));
    }
    e.preventDefault();
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
      {values.map((value, index) => (
        <input
          key={index}
          id={`digit-${index}`}
          type="text"
          inputMode="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            fontSize: "24px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
      ))}
    </div>
  );
};

const ApproveModal: React.FC<{
  onClose: () => void;
  onReprove: () => void;
  email: string;
  refID: string;
  generatorName: string;
  updateData: {
    contractDealer: string;
    contractDealerName: string;
    status: string;
  };
  updateContractData?: {
    contractDate: string;
    contractValue: string;
    contractTotalValue: Number;
    contractValueUnity: Number;
    expirationDocument: {
      expirationDate: any;
      hasExpirationDate: boolean;
      warningPeriod: Number;
    };
  };
  material: {
    name: string;
    id: string;
    unity: string;
    isHazardousMaterial: boolean;
  };
}> = ({
  onClose,
  onReprove,
  email,
  refID,
  updateData,
  updateContractData,
  generatorName,
  material,
}) => {
  const [actualOption, setActualOption] = useState("");
  const { currentUser, userDoc } = useAuth();
  const originId = userDoc?.originId;
  const [token, setToken] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reasonReprove, setReasonReprove] = useState("");
  const { setAlert } = useAlert();
  const { scrapId } = useParams<{ scrapId: string }>();

  function emailReprovalContent(data: ScrapDTO): string {
    return `<div>
      <h2>${i18n.t("label-reproved-proposal", { lng: "pt" })}</h2>
      <ul>
        <li><strong>${i18n.t("label-material", { lng: "pt" })}:</strong>
          ${data.scrapInfo.scrapCategory.description.name}
        </li>
        <li><strong>${i18n.t("treatment", { lng: "pt" })}:</strong>
          ${data.scrapInfo.treatmentMTRCode.name}
        </li>
        <li><strong>${i18n.t("label-scrap-generator", { lng: "pt" })}:</strong>
          ${data.scrapInfo.originName}
        </li>
        <li><strong>${i18n.t("label-reason", { lng: "pt" })}:</strong>
          ${reasonReprove}
        </li>
      </ul>
    </div>`;
  }

  async function handleConfirmReprovalProposal() {
    if (scrapId) {
      await getScrapAdminById(scrapId).then((scrapData) => {
        if (scrapData) {
          sendEmailSDM(
            `${t("label-reproved-proposal")}`,
            generateEmailTemplate(`${emailReprovalContent(scrapData)}`)
          );
          updateOnlyFieldOfForm(
            scrapData.id,
            COLLECTIONS.SCRAP_BY_GENERATOR,
            "reasonDisapproval",
            reasonReprove
          );
          updateOnlyFieldOfForm(
            scrapData.id,
            COLLECTIONS.SCRAP_BY_GENERATOR,
            "dateDisapproval",
            new Date()
          );
          updateOnlyFieldOfForm(
            scrapData.id,
            COLLECTIONS.SCRAP_BY_GENERATOR,
            "status",
            StatusEnum.DISAPPROVED
          ).then(() => {
            onReprove();
          });
        }
      });
    }
  }

  async function handlerActualOption(arg: string) {
    setActualOption(arg);
    if (arg != "reprove") {
      await callFunction("sendverificationcode", { email: email });
    }
  }

  if (!currentUser) return null;

  return (
    <div
      className="modal-content"
      style={{ gap: "20px", padding: "2rem 1rem", maxHeight: "500px" }}
    >
      {!agreeWithTerms && (
        <div style={{ padding: "2rem" }}>
          <div>
            <h1>Termos e condições</h1>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officia,
            voluptatibus suscipit praesentium sed tempore optio consectetur
            accusamus earum quisquam a aliquid nulla et dolorem facere
            doloremque neque dolores ut corrupti. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Vitae nulla perspiciatis dicta,
            placeat perferendis libero quisquam cum autem veritatis. Esse at
            doloremque voluptas quibusdam non eum perspiciatis perferendis
            commodi enim. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Quod vitae voluptatibus, eveniet veritatis cupiditate porro
            laborum perferendis nulla praesentium repellat nihil voluptates,
            voluptatem sed optio consequatur minus tenetur, tempora ducimus?
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi
            quibusdam odit, quos ipsa repellendus sequi quas sapiente ut ad
            laboriosam distinctio impedit quod commodi perspiciatis fugit cum,
            alias eaque corporis? Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Nemo omnis illum ut asperiores saepe! Temporibus
            quidem quo molestiae nobis asperiores nihil id, dicta sit aperiam
            autem consequatur molestias facere tempore. Lorem ipsum dolor sit
            amet consectetur, adipisicing elit. At, quisquam non nisi
            voluptatibus quas id sunt. Dolorem modi amet veritatis voluptatum
            dolor dignissimos sed obcaecati, distinctio sequi, eligendi optio
            neque? Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Minus, architecto libero unde aut ex vero blanditiis sed quas, quis
            veritatis quia tempora facilis fugiat adipisci tenetur eum magni!
            Similique, reprehenderit. Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Excepturi vero maxime accusamus? Consequatur hic
            repellat sapiente tempora magnam sunt quo sequi nesciunt modi, totam
            assumenda non aliquam impedit molestias deserunt. Lorem ipsum dolor
            sit, amet consectetur adipisicing elit. Odio dolore odit,
            reprehenderit suscipit, error ut eaque eos voluptate eveniet autem
            cumque, fugiat ducimus! Nobis quidem nihil quo obcaecati totam est!
          </div>
          <div className="flex-column" style={{ marginTop: "1rem" }}>
            <label
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <input
                defaultChecked
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                style={{ cursor: "pointer" }}
              />
              <p>{t("label-agree")}</p>
            </label>

            {checked ? (
              <button
                type="button"
                className="green"
                onClick={() => setAgreeWithTerms(true)}
              >
                {t("agree")}
              </button>
            ) : (
              <button type="button" onClick={onClose}>
                {t("label-cancel")}
              </button>
            )}
          </div>
        </div>
      )}

      {!actualOption && agreeWithTerms && (
        <>
          <h3 style={{ margin: 0 }}>{t("label-proposal-confirmation")}</h3>
          <p style={{ fontSize: "18px", marginBottom: 10 }}>
            {t("label-proposal")}
          </p>
          <div
            className="flex-row buttons-section"
            style={{ gap: "6px", justifyContent: "space-between" }}
          >
            <button type="button" onClick={() => onClose()}>
              {t("label-back")}
            </button>
            <div className="flex-row" style={{ gap: 10 }}>
              <button
                type="button"
                className="red"
                onClick={() => handlerActualOption("reprove")}
              >
                {t("label-reprove")}
              </button>
              <button
                type="submit"
                className="green"
                onClick={() => handlerActualOption("approve")}
              >
                {t("label-approve")}
              </button>
            </div>
          </div>
        </>
      )}

      {actualOption && actualOption != "reprove" && (
        <>
          <div className="flex-column" style={{ gap: "1rem" }}>
            <CTAInfoComponent>
              <div className="flex-row align-center" style={{ gap: 10 }}>
                <SparkIcon noPadding icName="info-i" pallete="primary" />
                <Trans i18nKey={t("cta-info.token-sent-email-contract")} />
              </div>
            </CTAInfoComponent>

            <label htmlFor="name">{t("label-token-request")}</label>
            <TokenInput onComplete={setToken} value={token}></TokenInput>
          </div>
          <div className="flex-row buttons-section" style={{ gap: "6px" }}>
            <button
              type="submit"
              className="green"
              onClick={() =>
                callFunction("sendverificationcode", { email: email }).then(
                  () => {
                    setAlert({
                      text: `${t("label-token-sent")}`,
                      type: "info",
                    });
                  }
                )
              }
            >
              {t("label-resend-mail")}
            </button>
            <button type="button" onClick={() => onClose()}>
              {t("label-cancel")}
            </button>
            {token && (
              <button
                className={actualOption === "approve" ? "green" : "red"}
                onClick={() => {
                  callFunction("receiveverificationcode", {
                    email: email,
                    code: token,
                  })
                    .then((response: any) => {
                      if (response.data) {
                        updateAsync(
                          COLLECTIONS.SCRAP_BY_GENERATOR,
                          {
                            approveData: {
                              user: userDoc,
                              status: actualOption,
                              date: `${new Date().toISOString()}`,
                            },
                            ...updateData,
                            ...updateContractData,
                          },
                          refID
                        )
                          .then((response) => {
                            if (updateContractData) {
                              updateAsync(
                                COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT,
                                {
                                  ...updateContractData,
                                },
                                refID
                              );
                              update(
                                COLLECTIONS.SCRAP_DEALERS,
                                { customers: arrayUnion(originId) },
                                updateData.contractDealer
                              );

                              if (material.isHazardousMaterial) {
                                let cadri: CADRItemDTO = {
                                  date: new Date(),
                                  quantityApproved: "",
                                  quantityCollected: 0,
                                  scrapByGeneratorId: refID,
                                  scrapMaterialName: material.name,
                                  generatorId: originId ?? "",
                                  scrapGeneratorName: generatorName,
                                  scrapDealerName:
                                    updateData.contractDealerName,
                                  contractedDealerId: updateData.contractDealer,
                                  scrapMaterial: material.id,
                                  unity: material.unity,
                                  isHazardousMaterial:
                                    material.isHazardousMaterial,
                                };

                                addNewCadri(cadri, []);

                                let expiration: ExpirationDTO = {
                                  docName: "CADRI",
                                  expirationDate: formatDateToStringYearFirst(
                                    new Date()
                                  ),
                                  expirationDateTimeStamp: new Date(),
                                  hasExpirationDate: true,
                                  origin: generatorName,
                                  originId: originId ?? "",
                                  solved: false,

                                  startWarningDate: new Date(),
                                  warningPeriod: 0,
                                };
                                setExpirationDocument(
                                  expiration,
                                  "CADRI" + new Date().toISOString()
                                );
                              }
                            }
                          })
                          .then((response) => {
                            onClose();
                            sendEmailSDM(
                              "Novo contrato aceito",
                              `
                              Contrato aceito <br/>
                              Gerador: ${generatorName} <br/>
                              Receptor: ${updateData.contractDealerName} <br/>
                              Material: ${material.name} <br/>
                            `
                            );
                          })
                          .catch((error) => {
                            setAlert({
                              text: `${t("alert-error-registered")}`,
                              type: "error",
                            });
                          });
                      } else {
                        setToken("");
                        setAlert({
                          text: `${t("label-wrong-token")}`,
                          type: "error",
                        });
                      }
                    })
                    .catch((error) => {
                      setAlert({
                        text: `${t("alert-error-registered")}`,
                        type: "error",
                      });
                    });
                }}
              >
                {t(`label-${actualOption}`)}
              </button>
            )}
          </div>
        </>
      )}
      {actualOption && actualOption == "reprove" && (
        <>
          <h3 style={{ margin: 0 }}>{t("label-reproval-reason-text")}</h3>
          <div className="flex-column">
            <label htmlFor="reproval-reason" style={{ marginBottom: 10 }}>
              {t("label-reason")}
            </label>
            <textarea
              style={{ maxWidth: "97%", minWidth: "97%" }}
              placeholder={t("label-reason")}
              id="reproval-reason"
              onChange={(e) => {
                setReasonReprove(e.target.value);
              }}
            />
          </div>
          <div className="flex-row justify-end" style={{ gap: 10 }}>
            <button type="button" onClick={() => onClose()}>
              {t("label-cancel")}
            </button>
            <button
              disabled={reasonReprove == ""}
              type="button"
              className={reasonReprove ? "red" : ""}
              onClick={() => {
                handleConfirmReprovalProposal();
              }}
            >
              {t("label-confirm-reproval")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ApproveModal;
