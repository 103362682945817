import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  callFunction,
  saveFiles,
  saveImages,
  saveNewAsync,
  saveNewWithId,
  update,
  updateOnlyFieldOfForm,
} from "../../services/persistence";
import {
  DealersInvitedDTO,
  ObservationsDTO,
  ScrapBidDTO,
  ScrapContractDTO,
  ScrapDTO,
  ScrapInfoDTO,
  ScrapNegotiationDTO,
} from "./scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";

import { ScrapGeneratorInfoDTO } from "../scrap-generators/scrap-generators-DTO";
import { ScrapDealerInfoDTO } from "../scrap-dealers/scrap-dealer-DTO";
import { setExpirationDocument } from "../../components/Expiration/ExpirationService";
import { StatusEnum } from "../../assets/enums/status-enum";
import { generateEmailTemplate } from "../../assets/templates/email-template";
import { DealersInvitedTableDTO } from "./scrap-generator-scraps-view/components/scrap-form/models/dealers-invited-DTO";
import i18n, { defaultLanguage } from "../../assets/i18n/i18n";
import { PrivateBidDTO } from "./scrap-admin-generator-view/components/scrap-form/models/private-bid-DTO";
import { sendEmailSDM } from "../../services/send-email-SDM";
import { CADRItemDTO } from "../collects/models/cadri-item-DTO";
import { addNewCadri } from "../collects/cadri-service";
import { ExpirationDTO } from "../../components/Expiration/models/ExpirationDTO";
import {
  formatDateToStringYearFirst,
  reciveDaysAndtransformInYearsOrMonths,
  transformDateFormat,
} from "../../services/date-format";
import { receiveNumberFormatWithMilhar } from "../../services/number-format";
import { calculatePriceForContractFromCurrency } from "../../services/currency";
import { ContactInfoDTO } from "../contacts/contacts-DTO";
import { getResizedImage } from "../../services/image-treatment";
import { ScrapsCategoryGroupDTO } from "./scrap-category/models/scrap-category-DTO";

export async function addNewScrapAdmin(
  data: ScrapDTO,
  originId: string,
  updateOnlyInvitedTable: boolean = false,
  images: File[],
  language?: string
): Promise<string | void> {
  if (data.scrapInfo.operationRecipientContractedCompany == undefined) {
    data.scrapInfo.operationRecipientContractedCompany = true;
  }
  if (data.scrapInfo.operationCarrierContractedCompany == undefined) {
    data.scrapInfo.operationCarrierContractedCompany = true;
  }
  if (data.scrapInfo.operationTempStorageContractedCompany == undefined) {
    data.scrapInfo.operationTempStorageContractedCompany = true;
  }
  data.scrapInfo.targetPrice = data.scrapNegotiation?.targetPrice;
  data.scrapInfo.targetDate = data.scrapNegotiation?.salesDate;
  data.scrapInfo.originId = originId;
  data.scrapInfo.originName = await getOriginName(originId);

  if (data.scrapInfo.treatmentMTRCode) {
    data.scrapInfo.treatmentMTRCode = {
      code: Number(data.scrapInfo.treatmentMTRCode?.code) || 0, // Converte para número
      name: data.scrapInfo.treatmentMTRCode?.name || "",
    };
  }

  if (data.scrapInfo.targetPrice == undefined) {
    data.scrapInfo.targetPrice = "";
    data.scrapNegotiation.targetPrice = "";
  }
  if (!data.scrapInfo.contractRecurrent) {
    data.scrapInfo.contractRecurrent = false;
  }

  if (data.scrapNegotiation && !data.scrapNegotiation.bidNegotiationType) {
    data.scrapNegotiation.bidNegotiationType = "open";
  }

  if (data.scrapInfo.containerNeeded != false) {
    data.scrapInfo.containerNeeded = true;
  }
  if (
    data.scrapInfo.contractPeriodDays > 0 &&
    data.scrapInfo.pickUpFrequency &&
    data.scrapInfo.quantity
  ) {
    data.scrapInfo.contractTotalQuantityPreview =
      calculatePreviewTotalQuantity(data);
  }

  if (
    data.scrapInfo.contractPeriodDays > 0 &&
    data.scrapInfo.pickUpFrequency &&
    data.scrapInfo.quantity &&
    data.scrapInfo.targetPrice
  ) {
    data.scrapInfo.contractTotalPricePreview = calculatePreviewTotalPrice(data);
  }

  if (
    data.scrapInfo.contractDealer != undefined &&
    data.scrapInfo.contractDealer.length > 1
  ) {
    data.scrapInfo.contractTotalPrice = calculateContractTotalPrice(data);
    data.scrapInfo.contractDealerName = await getDealerName(
      data.scrapInfo.contractDealer
    );
  }
  data.scrapInfo.status = defineScrapByGeneratorStatus(data);
  if (data.id == undefined) {
    const docRef = await saveNewAsync(
      COLLECTIONS.SCRAP_BY_GENERATOR,
      data.scrapInfo
    );

    if (data.scrapInfo.creation.createdBy == "scrap-generator") {
      await sendEmailSDM(
        "Novo Pedido de Bid",
        data.scrapInfo.originName + " enviou um novo pedido de BID."
      );
    }

    if (images != null && images.length > 0) {
      saveImages(COLLECTIONS.SCRAP_BY_GENERATOR, docRef.id, "scrap", images);
    }

    await Promise.all([
      saveNewWithId(
        COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
        data.dealersInvitedTable,
        docRef.id
      ),

      ...(data.scrapContract.expirationDocument != null &&
      data.scrapContract.expirationDocument.hasExpirationDate != false
        ? [
            ((data.scrapContract.expirationDocument.originId = docRef.id),
            (data.scrapContract.expirationDocument.origin = `${
              data.scrapInfo.originName || " "
            } - ${data.scrapInfo.contractDealerName || " "} (${
              data.scrapInfo.scrapCategory.name || " "
            })`),
            setExpirationDocument(
              data.scrapContract.expirationDocument,
              docRef.id
            )),
          ]
        : []),

      data.observations != undefined &&
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id),

      saveNewWithId(
        COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION,
        data.scrapNegotiation,
        docRef.id
      ),
      data.scrapBid?.bidValue != undefined
        ? saveNewWithId(
            COLLECTIONS.SCRAP_BY_GENERATOR_BID,
            data.scrapBid,
            docRef.id
          )
        : Promise.resolve(),
      data.scrapInfo.contractDealer != undefined
        ? saveContract(data, docRef.id)
        : Promise.resolve(),
    ]);

    return docRef.id;
  } else {
    if (updateOnlyInvitedTable) {
      await getScrapAdminById(data.id).then((oldTableData) => {
        let winnerOldTable =
          !!oldTableData?.dealersInvitedTable.dealersInvitedList.find(
            (obj) => obj.status === "WINNER"
          );

        let winnerNewTable = !!data.dealersInvitedTable.dealersInvitedList.find(
          (obj) => obj.status === "WINNER"
        );

        if (winnerNewTable && !winnerOldTable) {
          sendWinnerEmail(data.scrapInfo.originId, data, language);
        }
      });

      data.dealersInvitedTable.dealersInvitedList.forEach((dealer) => {
        if (Array.isArray(dealer.attachment)) {
          dealer.attachment = dealer.attachment.filter(
            (attachment) => attachment.trim() !== ""
          );
        }
      });

      await Promise.all([
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          data.dealersInvitedTable,
          data.id
        ),
        update(COLLECTIONS.SCRAP_BY_GENERATOR_BID, data.scrapBid, data.id),
      ]);
      const status = defineScrapByGeneratorStatus(data);
      data.scrapInfo.status = status;
      update(COLLECTIONS.SCRAP_BY_GENERATOR, { ...data.scrapInfo }, data.id);
    } else {
      if (images != null && images.length > 0) {
        saveImages(COLLECTIONS.SCRAP_BY_GENERATOR, data.id, "scrap", images);
      }
      await Promise.all([
        update(COLLECTIONS.SCRAP_BY_GENERATOR, data.scrapInfo, data.id),
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          data.dealersInvitedTable,
          data.id
        ),
        ...(data.scrapContract.expirationDocument != null &&
        data.scrapContract.expirationDocument.hasExpirationDate != false
          ? [
              (data.scrapContract.expirationDocument.originId = data.id),
              (data.scrapContract.expirationDocument.origin = `${
                data.scrapInfo.originName
              } - ${data.scrapInfo.contractDealerName || " "} (${
                data.scrapInfo.scrapCategory.name || " "
              })`),
              setExpirationDocument(
                data.scrapContract.expirationDocument,
                data.id
              ),
            ]
          : []),
        data.observations != undefined &&
          update(COLLECTIONS.OBSERVATIONS, data.observations, data.id),
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION,
          data.scrapNegotiation,
          data.id
        ),
        data.scrapBid?.bidValue != undefined
          ? update(COLLECTIONS.SCRAP_BY_GENERATOR_BID, data.scrapBid, data.id)
          : Promise.resolve(),
        data.scrapInfo.contractDealer != undefined
          ? updateContract(data, data.id)
          : Promise.resolve(),
      ]);
      if (
        data.scrapInfo.contractDealer != undefined &&
        data.scrapInfo.contractDealer.length > 0
      ) {
        update(
          COLLECTIONS.SCRAP_DEALERS,
          { customers: arrayUnion(data.scrapInfo.originId) },
          data.scrapInfo.contractDealer
        );
      }
    }
  }
}

function saveContract(data: ScrapDTO, id: string) {
  data.scrapContract.contractDate = new Date().toISOString();

  data.scrapContract.contractValueUnity = parseFloat(
    data.scrapContract.contractValue?.match(/[\d.,]+/)?.[0].replace(",", ".")
  );

  saveNewWithId(
    COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT,
    data.scrapContract,
    id
  );
  update(COLLECTIONS.SCRAP_BY_GENERATOR, data.scrapContract, id);
  if (data.scrapInfo.scrapCategory.isHazardousMaterial) {
    let cadri: CADRItemDTO = {
      date: new Date(),
      quantityApproved: "",
      quantityCollected: 0,
      scrapByGeneratorId: data.id,
      scrapMaterialName: data.scrapInfo.scrapCategory.name,
      generatorId: data.scrapInfo.originId,
      scrapGeneratorName: data.scrapInfo.originName,
      scrapDealerName: data.scrapInfo.contractDealerName,
      contractedDealerId: data.scrapInfo.contractDealer,
      scrapMaterial: data.scrapInfo.scrapCategory.id,
      unity: data.scrapInfo.unity,
      isHazardousMaterial: data.scrapInfo.scrapCategory.isHazardousMaterial,
    };

    addNewCadri(cadri, []);

    let expiration: ExpirationDTO = {
      docName: "CADRI",
      expirationDate: formatDateToStringYearFirst(new Date()),
      expirationDateTimeStamp: new Date(),
      hasExpirationDate: true,
      origin: data.scrapInfo.originName,
      originId: data.scrapInfo.originId,
      solved: false,
      startWarningDate: new Date(),
      warningPeriod: -1,
    };
    setExpirationDocument(expiration, "CADRI" + new Date().toISOString());
  }
}

function updateContract(data: ScrapDTO, id: string) {
  data.scrapContract.contractValueUnity = parseFloat(
    data.scrapContract.contractValue?.match(/[\d.,]+/)?.[0].replace(",", ".")
  );

  update(COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT, data.scrapContract, id);
  update(COLLECTIONS.SCRAP_BY_GENERATOR, data.scrapContract, id);
}

function calculateNumberofDeliveries(data: ScrapDTO) {
  let diferencaEmDias =
    data.scrapInfo.status == "CONTRACTED"
      ? Math.floor(
          (new Date(
            data.scrapContract.expirationDocument.expirationDate
          ).getTime() -
            new Date(data.scrapContract.contractDate).getTime()) /
            (1000 * 60 * 60 * 24)
        )
      : data.scrapInfo.contractPeriodDays;

  if (diferencaEmDias == 0) diferencaEmDias = 1;

  let numberOfDeliveries = 1;

  if (data.scrapInfo.period === "YEARLY")
    numberOfDeliveries = Math.floor(diferencaEmDias / 365);
  else if (data.scrapInfo.period === "MONTHLY")
    numberOfDeliveries = Math.floor(diferencaEmDias / 30);
  else if (data.scrapInfo.period === "BIWEEKLY")
    numberOfDeliveries = Math.floor(diferencaEmDias / 15);
  else if (data.scrapInfo.period === "WEEKLY")
    numberOfDeliveries = Math.floor(diferencaEmDias / 7);
  else if (data.scrapInfo.period === "DAILY")
    numberOfDeliveries = diferencaEmDias;
  else if (data.scrapInfo.period == "SINGLE_SALE") numberOfDeliveries = 1;
  return numberOfDeliveries < 1 ? 1 : numberOfDeliveries;
}

function calculatePreviewTotalQuantity(data: ScrapDTO) {
  return (
    calculateNumberofDeliveries(data) *
    parseInt(data.scrapInfo.quantity.replace(/\./g, ""))
  );
}

function calculatePreviewTotalPrice(data: ScrapDTO) {
  return (
    calculatePreviewTotalQuantity(data) *
    parseFloat(
      data.scrapInfo.targetPrice
        .replace(/[^\d.,]/g, "")
        .replace(",", ".")
        .replace("R$", "")
    )
  );
}

function calculateContractTotalPrice(data: ScrapDTO) {
  return (
    calculatePreviewTotalQuantity(data) *
    parseFloat(
      data.scrapContract.contractValue
        .replace(/[^\d.,]/g, "")
        .replace(",", ".")
        .replace("R$", "")
    )
  );
}

export function addScrapCategoryTotalQuotation(scrapAdmin: ScrapDTO) {
  let avg = 0;
  let qty = 0;

  scrapAdmin.dealersInvitedTable.dealersInvitedList.forEach((dealerInvite) => {
    if (
      dealerInvite.proposedValue &&
      dealerInvite.proposedValue != null &&
      dealerInvite.proposedValue != " "
    ) {
      qty++;
      avg =
        avg +
        parseFloat(
          dealerInvite.proposedValue
            .replace(/[^\d.,]/g, "")
            .replace(",", ".")
            .replace("R$", "")
        );
    }
  });

  if (qty > 0) {
    callFunction(
      "addscrapcategorytotalquotation",
      {
        scrapCategoryId: scrapAdmin.scrapInfo.scrapCategory.id,
        price: avg / qty,
        country: scrapAdmin.scrapInfo.operationAddress.country,
        state: scrapAdmin.scrapInfo.operationAddress.state,
      },
      scrapAdmin.id
    );
  }
}

export async function getPrivateBidList(id: string) {
  const contactsCollectionRef = collection(
    firestore,
    COLLECTIONS.DEALERS_PRIVATE_BID
  );

  const q = query(contactsCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as PrivateBidDTO[];
  return items;
}

export function defineScrapByGeneratorStatus(data: ScrapDTO) {
  let status = "TO_DEAL";
  if (
    data.scrapInfo.status == StatusEnum.CANCELED ||
    data.scrapInfo.status == StatusEnum.DISAPPROVED ||
    data.scrapInfo.status == StatusEnum.FINISHED
  ) {
    return data.scrapInfo.status;
  }
  if (data.scrapInfo.operationDate) {
    return "OPERATING";
  }
  if (
    data.scrapInfo.contractDealer &&
    data.scrapContract?.contractDate &&
    data.scrapContract?.contractValue &&
    data.scrapContract?.expirationDocument?.expirationDate
  ) {
    return "CONTRACTED";
  }
  if (
    data.dealersInvitedTable?.dealersInvitedList?.some(
      (item) => item.status === "WINNER"
    )
  ) {
    return "BID_COMPLETED";
  }
  if (
    data.dealersInvitedTable?.dealersInvitedList?.some(
      (item) => item.proposedValue !== " "
    )
  ) {
    return "IN_BID";
  }
  if (data.dealersInvitedTable?.dealersInvitedList?.length > 0) {
    return "AWAITING_BID";
  }
  return status;
}

export async function cancelScrapAdmin(id: string): Promise<void> {
  await update(COLLECTIONS.SCRAP_BY_GENERATOR, { status: "CANCELED" }, id);
}

export async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_GENERATORS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapGeneratorInfoDTO;
  return dealer.tradingName;
}

async function getDealerName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

// get all scrapAdmins
export async function getScrapAdmin(): Promise<ScrapInfoDTO[]> {
  const querySnapshot = await getDocs(
    collection(firestore, COLLECTIONS.SCRAP_DEALERS)
  );
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ScrapInfoDTO[];
  return items;
}

// get scrapAdmin details
export const getScrapAdminById = async (
  id: string
): Promise<ScrapDTO | null> => {
  const scrapInfo = await getscrapInfo(id);
  const observations = await getScrapAdminObservations(id);
  const scrapNegotiation = await getScrapAdminNegotiation(id);
  const scrapBid = await getScrapAdminBid(id);
  const scrapContract = await getScrapAdminContract(id);
  const dealersInvitedTable = await getScrapAdminInvitedDelaersTable(id);

  return {
    id,
    scrapInfo,
    observations,
    scrapNegotiation,
    scrapBid,
    scrapContract,
    dealersInvitedTable,
  };
};

export async function getScrapAdminInvitedDelaersTable(
  id: string
): Promise<DealersInvitedDTO> {
  const docRef = doc(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
    id
  );
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DealersInvitedDTO;
}

export async function getScrapAdminContract(
  id: string
): Promise<ScrapContractDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT, id);
  const docSnap = await getDoc(docRef);

  return { id: docSnap.id, ...docSnap.data() } as ScrapContractDTO;
}

export async function getScrapAdminBid(id: string): Promise<ScrapBidDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_BID, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapBidDTO;
}

export async function getscrapInfo(id: string): Promise<ScrapInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR, id);
  const docSnap = await getDoc(docRef);

  return { id: docSnap.id, ...docSnap.data() } as ScrapInfoDTO;
}

export async function getScrapAdminNegotiation(
  id: string
): Promise<ScrapNegotiationDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapNegotiationDTO;
}

export async function getScrapAdminObservations(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

export async function fetchFulfilledRequirements(id: string): Promise<any> {
  const docRef = doc(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS_DEALER_TOTAL,
    id
  );
  const docSnap = await getDoc(docRef);

  return docSnap.data();
}

export async function fetchPartnerRequirementsTotalByCountry(
  countryName: string
) {
  const partnerRequirementsCollectionRef = collection(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS
  );
  const q = query(
    partnerRequirementsCollectionRef,
    where("country", "in", [countryName])
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as any;

  return items;
}

export async function sendWinnerEmail(
  id: string,
  scrapAdmin: ScrapDTO,
  language: string = "en"
) {
  const docRefContact = doc(firestore, COLLECTIONS.CONTACTS, id + "contact");
  const docSnap = await getDoc(docRefContact);
  const contact = docSnap.data() as ContactInfoDTO;

  await i18n.changeLanguage(language);

  if (contact.email != null && contact.email.length > 0 && scrapAdmin) {
    const emailContent = `
      <div>
        <h2>${contact.name}, ${i18n.t(`statusEnum.BID_COMPLETED`)}!</h2>
        <p>${i18n.t("label-best-price-we-found")}</p>
        <p><strong>${i18n.t("label-details")}:</strong></p>
        <ul>
          <li><strong>${i18n.t("label-material")}:</strong>
            ${scrapAdmin.scrapInfo.scrapCategory.name}
          </li>
          <li><strong>${i18n.t(
            "material-bid-date"
          )}:</strong> ${transformDateFormat(scrapAdmin.scrapBid.bidDate)}</li>
           <li><strong>${i18n.t(
             "contract-period"
           )}:</strong> ${reciveDaysAndtransformInYearsOrMonths(
      scrapAdmin.scrapInfo.contractPeriodDays
    )}</li>
          <li><strong>${i18n.t("label-best-offer")}:</strong> ${
      scrapAdmin.dealersInvitedTable?.dealersInvitedList?.find(
        (obj) => obj.status === "WINNER"
      )?.proposedValue
    }/${i18n.t(`unityENUM.${scrapAdmin.scrapInfo.unity}`)}</li>
          <li><strong>${i18n.t(
            "label-estimated-contract-volume"
          )}:</strong> ${receiveNumberFormatWithMilhar(
      scrapAdmin.scrapInfo.contractTotalQuantityPreview
    )} ${i18n.t(`unityENUM.${scrapAdmin.scrapInfo.unity}`)}</li>
          <li><strong>${i18n.t(
            "label-estimated-contract-value"
          )}:</strong> ${calculatePriceForContractFromCurrency(
      scrapAdmin.dealersInvitedTable?.dealersInvitedList?.find(
        (obj) => obj.status === "WINNER"
      )?.proposedValue || "",
      scrapAdmin.scrapInfo.contractTotalQuantityPreview
    )}</li>
        </ul> 
        <p>${i18n.t("waiting-your-agreement")}<p>
      </div>
    `;

    const content = generateEmailTemplate(emailContent);

    callFunction("sendemail", {
      to: contact.email,
      subject: i18n.t("material-best-price"),
      content: content,
    });

    await i18n.changeLanguage(defaultLanguage());
  }
}

function generateDealerEmailContent(
  data: ScrapDTO | null,
  contact: ContactInfoDTO,
  language: string
): string {
  return `
    <div>
      <h2>${contact?.name}, ${i18n.t("invite-for-bid-subject", {
    lng: language,
  })}!</h2>
      <p>${i18n.t("bid-email.invite-for-bid-intro", { lng: language })}</p>
      <p>${data?.scrapBid?.requirimentsApprovalBid ?? ""}</p>
      <p><strong>${i18n.t("label-details", { lng: language })}:</strong></p>
      <ul>
        <li><strong>${i18n.t("label-material", { lng: language })}:</strong> ${
    data?.scrapInfo?.scrapCategory?.name
  }</li>
          <li><strong>${i18n.t("material-special-description", {
            lng: language,
          })}:</strong> ${data?.scrapInfo.specialDescription}</li>
        <li><strong>${i18n.t("label-estimated-quantity", {
          lng: language,
        })}:</strong> ${data?.scrapInfo.quantity} (${i18n.t(
    `unityENUM.${data?.scrapInfo.unity}`,
    { lng: language }
  )}) - ${i18n.t(`periodENUM.${data?.scrapInfo.period}`, {
    lng: language,
  })}</li>
        <li><strong>${i18n.t("material-pick-up-frequency", {
          lng: language,
        })}:</strong> ${i18n.t(
    `periodENUM.${data?.scrapInfo.pickUpFrequency}`,
    { lng: language }
  )}</li>
        <li><strong>${i18n.t("label-responsible-shipping", {
          lng: language,
        })}:</strong> ${i18n.t(`label-${data?.scrapInfo.responsibleShipping}`, {
    lng: language,
  })}</li>
        <li><strong>${
          data?.scrapInfo.containerNeeded
            ? i18n.t(`material-container`)
            : i18n.t(`material-non-container`)
        }</strong></li>
${
  data?.scrapInfo.containerNeeded
    ? `
          <ul>
          <li><strong>${i18n.t("material-container-type", {
            lng: language,
          })}:</strong> ${
        data?.scrapInfo.containerNeeded
          ? data?.scrapInfo.containerType.name
          : "N/A"
      }</li>
          <li><strong>${i18n.t("material-container-size", {
            lng: language,
          })}:</strong> ${
        data?.scrapInfo.containerNeeded ? data?.scrapInfo.containerSize : "N/A"
      }</li>
          <li><strong>${i18n.t("label-quantity", {
            lng: language,
          })}:</strong> ${
        data?.scrapInfo.containerNeeded
          ? data?.scrapInfo.containerQuantity
          : "N/A"
      }</li>
        </ul>
  `
    : ``
}
        <li><strong>${i18n.t("label-observations-storage", {
          lng: language,
        })}:</strong> ${data?.scrapInfo.observationsStorage}</li>
        <li><strong>${i18n.t("label-contract-time-expectation", {
          lng: language,
        })}:</strong> ${reciveDaysAndtransformInYearsOrMonths(
    data?.scrapInfo.contractPeriodDays || 0
  )}</li>
        <li><strong>${i18n.t("label-region", { lng: language })}:</strong> ${
    data?.scrapInfo?.operationAddress?.city
  }, ${data?.scrapInfo?.operationAddress?.state}</li>
        <li><strong>${i18n.t("material-bid-date", {
          lng: language,
        })}:</strong> ${transformDateFormat(data?.scrapBid?.bidDate)}</li>
      </ul>
      <p>${i18n.t("answer-email-quotation", {
        lng: language,
      })}
      </p>
    </div>
  `;
}

export async function sendDealerEmail(
  scrapId: string,
  dealerId: string,
  language: string = "en"
) {
  const docRefContact = doc(
    firestore,
    COLLECTIONS.CONTACTS,
    dealerId + "contact"
  );
  const docSnap = await getDoc(docRefContact);

  const contact = docSnap.data() as ContactInfoDTO;
  const data = await getScrapAdminById(scrapId);

  if (contact.email != null && contact.email.length > 0) {
    const emailContent = generateDealerEmailContent(data, contact, language);
    const content = generateEmailTemplate(emailContent);

    const attachments =
      data?.scrapInfo?.images && data?.scrapInfo?.images.length > 0
        ? data?.scrapInfo?.images.map((imageUrl: string) => ({
            filename: `${data?.scrapInfo?.scrapCategory?.name}.jpg`,
            path: getResizedImage(imageUrl),
          }))
        : data?.scrapInfo?.scrapCategory?.image
        ? [
            {
              filename: `${data?.scrapInfo.scrapCategory.name}.jpg`,
              path: getResizedImage(data?.scrapInfo?.scrapCategory?.image),
            },
          ]
        : null;

    const emailData: any = {
      to: contact.email,
      subject: i18n.t("invite-for-bid-subject", { lng: language }),
      content: content,
    };

    if (attachments) {
      emailData.attachments = attachments;
    }

    await callFunction("sendemail", emailData);
  }

  const docRef = doc(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
    scrapId
  );

  getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        const dealersInvitedListt = doc.data().dealersInvitedList;

        const updatedDealersInvitedList = dealersInvitedListt.map(
          (dealer: { id: string }) => {
            if (dealer.id === dealerId) {
              return { ...dealer, inviteSended: true };
            }
            return dealer;
          }
        );

        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          { dealersInvitedList: updatedDealersInvitedList },
          scrapId
        );
      } else {
        console.error("Document not found");
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
    });
}

export async function sendMultDealerEmail(
  scrapId: string,
  dealerId: string,
  language: string = "en",
  idToUpdate?: string,
  formData?: any
) {
  try {
    sendDealerEmail(scrapId, dealerId, language);
  } catch (error) {
    console.error("Error sending multi-dealer email:", error);
  }
}

export async function addNewBID(id: string, data: ScrapBidDTO) {
  try {
    const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_BID, id);
    await setDoc(docRef, data);
    if (docRef) return true;
    else return false;
  } catch {
    return false;
  }
}

export async function getScrapGroupById(
  id: string
): Promise<ScrapsCategoryGroupDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_GROUPS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapsCategoryGroupDTO;
}

// add new
export async function addNewGroup(
  data: ScrapsCategoryGroupDTO,
  selectedImages: File[]
) {
  if (data.id == undefined) {
    saveNewAsync(COLLECTIONS.SCRAP_GROUPS, data).then((docRef) => {
      if (selectedImages.length > 0) {
        saveFiles(
          COLLECTIONS.SCRAP_GROUPS,
          docRef.id,
          COLLECTIONS.SCRAP_GROUPS,
          selectedImages,
          "images"
        );
      }
    });
  } else {
    update(COLLECTIONS.SCRAP_GROUPS, data, data.id);
    if (selectedImages.length > 0) {
      saveFiles(
        COLLECTIONS.SCRAP_GROUPS,
        data.id,
        COLLECTIONS.SCRAP_GROUPS,
        selectedImages,
        "images"
      );
    }
  }
}
