import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { DriversInfoDTO, DriversDTO, ObservationsDTO } from "./drivers-DTO";
import {
  saveFiles,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";
import { setExpirationDocument } from "../../components/Expiration/ExpirationService";
import { ScrapDealerInfoDTO } from "../scrap-dealers/scrap-dealer-DTO";

// add new
export async function addNewDriver(
  data: DriversDTO,
  id: string,
  
) {
  if (data.driversInfoDTO.expirationDocument != null) {
    data.driversInfoDTO.expirationDocument.originId = id;
    const originName =
      (await getOriginName(id)) + " - " + data.driversInfoDTO.name;
    data.driversInfoDTO.expirationDocument.origin = originName;
  }
  if (data.id == undefined) {
    data.driversInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.DRIVERS, data.driversInfoDTO).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (data.driversInfoDTO.expirationDocument != null) {
        setExpirationDocument(
          data.driversInfoDTO.expirationDocument,
          docRef.id
        );
      }
      
    });
  } else {
    update(COLLECTIONS.DRIVERS, data.driversInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    if (data.driversInfoDTO.expirationDocument != null) {
      setExpirationDocument(data.driversInfoDTO.expirationDocument, data.id);
    }
    
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getDriversById(id: string): Promise<DriversInfoDTO[]> {
  const driversCollectionRef = collection(firestore, COLLECTIONS.DRIVERS);

  const q = query(driversCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["name"].concat(
        " ",
        
        doc.data()["rg"],
        doc.data()["cnhValidate"],
        doc.data()["autorizationDate"]
      ),
  })) as unknown as DriversInfoDTO[];
  return items;
}

export const getDriverById = async (id: string): Promise<DriversDTO | null> => {
  const driversInfoDTO = await getDriver(id);
  const observations = await getVehicleObservation(id);

  return {
    id,
    driversInfoDTO,
    observations,
  };
};

export async function getDriversByGeneratorAndDealer(
  dealerId: string,
  generatorId: string
): Promise<DriversInfoDTO[]> {
  const driversCollectionRef = collection(firestore, COLLECTIONS.DRIVERS);

  const q = query(
    driversCollectionRef,
    where("originId", "==", dealerId),
    where("customers", "array-contains-any", [generatorId])
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as DriversInfoDTO[];

  return items;
}

export async function getDriver(id: string): Promise<DriversInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.DRIVERS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DriversInfoDTO;
}

export async function getVehicleObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
