import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ContainerTypesDTO } from "../container-types-DTO";
import { useAlert } from "../../../../../contexts/alert-context";
import { addNew, getById } from "../container-types-service";
import CountryInputAutocomplete from "../../../../../components/CountryInputAutocomplete/CountryInputAutocomplete";
import { CountryDTO } from "../../../../../components/CountryInputAutocomplete/models/country-DTO";

interface ModalProps {
  onClose: () => void;
  requirementId: string;
}

const ContainerTypesForm: React.FC<ModalProps> = ({
  onClose,
  requirementId,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [containerTypesDTO, setContainerTypesDTO] =
    useState<ContainerTypesDTO | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ContainerTypesDTO>();

  useEffect(() => {
    if (requirementId) {
      getById(requirementId).then((data) => {
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ContainerTypesDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ContainerTypesDTO, value as any);
            }
          });

          setValue(
            "containerTypesInfoDTO.country",
            data.containerTypesInfoDTO.country
          );
       setCountry(data.containerTypesInfoDTO.country);
        }
      });
    }
  }, [id, setValue, country]);

  const onSubmit = async (document: ContainerTypesDTO) => {
    try {
      if (id) {
        await addNew(document, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryDTO | null>(null);

  const handleCountryChange = (country: CountryDTO) => {
    setSelectedCountryDetails(country);

    setValue("containerTypesInfoDTO.country", country.country, {
      shouldValidate: true,
    });
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("material-container-type")}</h2>
          <div className="flex-column">
            <CountryInputAutocomplete
              label={t("label-country")}
              placeholder={t("placeholder.country")}
              register={register}
              errors={errors}
              onChange={handleCountryChange}
              initialValue={country}
            />
            {!selectedCountryDetails && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
          <div className="flex-column">
            <label htmlFor="material-container-type">
              {t("material-container-type")}
            </label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="material-container-type"
              {...register("containerTypesInfoDTO.name", {
                required: true,
              })}
            />
            {errors.containerTypesInfoDTO?.name && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
        </div>
        <br />

        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations" style={{ paddingBottom: 10 }}>
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContainerTypesForm;
