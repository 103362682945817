import React from "react";
import { t } from "i18next";
import { sendMultDealerEmail } from "../../../../scrap-admin-service";
import { useAlert } from "../../../../../../contexts/alert-context";
import {
  CountryConfigDTO,
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../../services/address-service";

interface ModalProps {
  onClose: () => void;
  multIDS: any[];
  id: string | any[];
  materialID?: string;
  sendedIdsHandler?: (arg: string[]) => void;
  formData?: any;
}

const ScrapDealersSendMailModal: React.FC<ModalProps> = ({
  onClose,
  multIDS,
  id,
  materialID,
  sendedIdsHandler,
  formData,
}) => {
  const { setAlert } = useAlert();

  const handlerSend = async () => {
    try {
      const addressPromises = multIDS.map((item) =>
        getAdrressByEntityId(item.id)
      );
      const addresses = await Promise.all(addressPromises);

      const countryPromises = addresses.map((data) =>
        getCountryConfigById(data.country)
      );
      const countryConfigs = await Promise.all(countryPromises);

      const emailPromises = multIDS.map(async (item, index) => {
        const countryConfig = countryConfigs[index];
        const language: string =
          countryConfig.locale.split("-")[0] === "pt" ? "pt" : "en";
        if (typeof id === "string") {
          return sendMultDealerEmail(id, item.id, language);
        } else {
          const materialEmailPromises = id.map((idToUpdate: any) => {
            if (materialID) {
              return sendMultDealerEmail(
                id[0].id,
                item.id,
                language,
                idToUpdate.id,
                formData
              );
            }
            return Promise.resolve();
          });
          if (sendedIdsHandler !== undefined) {
            sendedIdsHandler(multIDS.map((obj) => obj.id));
          }
          return Promise.all(materialEmailPromises);
        }
      });

      await Promise.all(emailPromises);

      setAlert({
        text: t("label-invite-sended"),
        type: "success",
      });

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setAlert({
        text: t("label-invite-"),
        type: "error",
      });
    }
  };

  return (
    <div className="modal-content" style={{ padding: "2rem" }}>
      <div className="flex-column margin-topbottom-1">
        <label>{t("label-send-mail-modal")}</label>
        {multIDS.map((obj) => (
          <input
            key={obj.id}
            style={{ marginTop: "1rem" }}
            type="text"
            disabled
            name="dealerName"
            value={obj.name}
          />
        ))}
      </div>
      <div className="flex-row buttons-section">
        <button
          className="neutral-white"
          onClick={onClose}
          type="button"
          style={{ margin: "1rem" }}
        >
          {t("label-back")}
        </button>
        <button
          style={{ margin: "1rem" }}
          className="blue"
          type="submit"
          onClick={handlerSend}
        >
          {t("label-send")}
        </button>
      </div>
    </div>
  );
};

export default ScrapDealersSendMailModal;
