import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import CardListScrapGenerator from "./components/cardlist-scrap-generator/cardlist-scrap-generator";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useEffect, useState } from "react";
import FilterComponent from "../../components/FIlter/filter";
import { useAuth } from "../../contexts/auth-context";
import { getScrapGenerators } from "./scrap-generator-service";
import { ScrapGeneratorInfoDTO } from "./scrap-generators-DTO";
import addIcon from "../../assets/svgs/add.svg";

const ScrapGenerators: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams();
  const [filteredData, setFilteredData] = useState<ScrapGeneratorInfoDTO[]>([]);
  const [scrapGenerators, setScrapGenerators] = useState<ScrapGeneratorInfoDTO[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("1");

  const handleFilterClick = (filteredData: ScrapGeneratorInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const sortByRegion = (data: ScrapGeneratorInfoDTO[]) => {
    return [...data].sort((a, b) => {
      if (a.region < b.region) return -1;
      if (a.region > b.region) return 1;
      return 0;
    });
  };

  const sortByLegalName = (data: ScrapGeneratorInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.legalName?.toString().toLowerCase() || '';
      const nameB = b.legalName?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-region"), sortFunction: sortByRegion },
    { label: t("label-legal-name"), sortFunction: sortByLegalName }
  ];

  const tabs = [
    { value: "1", label: t("label-list") },
    { value: "2", label: t("label-map") },
  ];

  const fetchScrapGenerators = async () => {
    try {
      const data = await getScrapGenerators();
      setScrapGenerators(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching scrap Generators: ", error);
    }
  };

  useEffect(() => {
    fetchScrapGenerators();
  }, []);

  const handleCloseModal = () => {
    fetchScrapGenerators();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => navigate("/scrap-generators-new")}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>

          <ImportExportCsv
            exportApi="exportscrapgeneratorscsv"
            importApi="importscrapgeneratorscsv"
            onClose={handleCloseModal}
          />

          <FilterComponent
            data={scrapGenerators}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}

          />
        </div>

        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
        />
        <CardListScrapGenerator scrapGenerators={filteredData} />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGenerators;
