import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useAlert } from "../../../contexts/alert-context";
import { ContactsDTO } from "../contacts-DTO";
import { addNewContact, getContactById } from "../contacts-service";
import InputMask from "react-input-mask";

interface ModalProps {
  onClose: () => void;
  contactId: string;
}

const ContactForm: React.FC<ModalProps> = ({ onClose, contactId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [contact, setContact] = useState<ContactsDTO | null>(null);
  const phoneRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ContactsDTO>();

  useEffect(() => {
    if (contactId) {
      getContactById(contactId).then((data) => {
        if (data) {
          setContact(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ContactsDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ContactsDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (contact: ContactsDTO) => {
    try {
      if (id) {
        await addNewContact(contact, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-contacts")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{t("label-contact-name")}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("contactInfoDTO.name", { required: true })}
            />
            {errors.contactInfoDTO?.name && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{t("label-email")}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("contactInfoDTO.email", {
                required: true,
              })}
            />
            {errors.contactInfoDTO?.email && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="phone">{t("label-phone")}</label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder={t("placeholder.phone")}
              type="text"
              id="phone"
              {...register("contactInfoDTO.phone", { required: true })}
              inputRef={phoneRef}
            />
            {errors.contactInfoDTO?.phone && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{t("label-function")}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("contactInfoDTO.function", {
                required: true,
              })}
            />
            {errors.contactInfoDTO?.type && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations">
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <br />
        <hr />

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
