import React, { useEffect } from "react";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { receiveNumberFormatWithMilhar } from "../../../../services/number-format";
import { useAuth } from "../../../../contexts/auth-context";
import Tooltip from '../../../../components/tooltip/tooltip';
import { CollectStatus } from "../../../../assets/enums/collects-enum";
interface MTRSectionProps {
  filteredData: CollectItemDTO[];
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  hovered: string;
  seeCollectDetailsModal: (collect: CollectItemDTO) => void;
  setMtrID?: React.Dispatch<React.SetStateAction<string>>;
  setMaterial?: React.Dispatch<React.SetStateAction<any>>;
  setCdfModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setTransitionData?: React.Dispatch<React.SetStateAction<any>>;
}

const MTRSection: React.FC<MTRSectionProps> = ({
  filteredData,
  setHovered,
  hovered,
  seeCollectDetailsModal,
  setMtrID,
  setMaterial,
  setCdfModal,
  setTransitionData,
}) => {
  const { id } = useParams<{ id: string }>();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  const tableHeader = [
    `${t("label-collects")}`,
    `${t("label-material")}`,
    `${t("label-indicated-quantity")}`,
    `${t("label-received-quantity")}`,
    `${t("label-difference")}`,
    `${t("MTR")}`,
    ...(id || profile === "scrap-generator" ? [`${t("CDF")}`] : []),
    `${t("label-status")}`,
  ];

  const cellStyle = {
    padding: "8px",
    textAlign: "center",
    verticalAlign: "middle",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "normal",
  };

  const specificStyles: { [key: number]: React.CSSProperties } = {
    0: { width: "13%", justifyContent: "center" },
    1: { width: "12%", justifyContent: "center" },
    2: { width: "13.5%", justifyContent: "flex-start" },
    3: { width: "16%", justifyContent: "center" },
    4: { width: "14.5%", justifyContent: "center" },
    5: { width: "8.5%", justifyContent: "center" },
    6: { width: "8.5%", justifyContent: "center" },
    7: { width: "14%", justifyContent: "center" },
  };

  return (
    <div className="info-section">
      <SparkTable>
        <SparkTableHead>
          <SparkTableRow>
            {tableHeader.map((header, index) => {
              const combinedStyle = { ...cellStyle, ...specificStyles[index] };
              return (
                <SparkTableCell key={index} style={combinedStyle}>
                  <strong>{header.toUpperCase()}</strong>
                </SparkTableCell>
              );
            })}
          </SparkTableRow>
        </SparkTableHead>
        <SparkTableBody>
          {filteredData.filter((obj) => obj.status != CollectStatus.SCHEDULED) 
          .map((rowItem: CollectItemDTO) => (
            <SparkTableRow
              key={rowItem.id}
              onMouseEnter={() => setHovered(rowItem.id)}
              onMouseLeave={() => setHovered("")}
              onClick={(e) => {
                e.stopPropagation();
                seeCollectDetailsModal(rowItem);
              }}
            >
              <SparkTableCell style={{ ...cellStyle, flex: "1.0" }}>
                {rowItem.id}
              </SparkTableCell>
              <SparkTableCell style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
                maxWidth: "130px",
                whiteSpace: "normal",
                flex: "1.2"
              }}>
                <Tooltip
                  text={
                    rowItem.isHazardousMaterial
                      ? t("label-hazardous-material")
                      : t("label-non-hazardous-material")
                  }
                >
                  <div>
                    {rowItem.isHazardousMaterial && (
                       <div   style={{ marginRight: "110px" }}> 
                        
                        <SparkIcon
                       
                          className="icon-flag"
                          no-padding={true}
                          icName={"danger-hazards"}
                        />
                      </div>
                    )}
                    <p >
                      {rowItem.scrapMaterialName}
                    </p>
                  </div>
                </Tooltip>
              </SparkTableCell>


              <SparkTableCell style={{ ...cellStyle, flex: "1.1", textAlign: "left" }}>
                {receiveNumberFormatWithMilhar(rowItem.quantity)}{" "}
                {t(`unityENUM.${rowItem.unity}`)}
              </SparkTableCell>
              <SparkTableCell style={{ ...cellStyle, flex: "1.2" }}>
                {rowItem.hasCDF ? (
                  <p>
                    {" "}
                    {receiveNumberFormatWithMilhar(rowItem.receivedQtd)}{" "}
                    {t(`unityENUM.${rowItem.unity}`)}
                  </p>
                ) : (
                  <p style={{ fontSize: "14px" }}>{t("label-await-cdf")}</p>
                )}
              </SparkTableCell>
              <SparkTableCell
                style={{
                  ...cellStyle,
                  flex: "1",
                }}
              >
                {rowItem.hasCDF ? (
                  <p style={{ color: "red" }}>
                    {receiveNumberFormatWithMilhar(
                      rowItem.receivedQtd - rowItem.quantity
                    )}{" "}
                    {t(`unityENUM.${rowItem.unity}`)}
                  </p>
                ) : (
                  <p style={{ fontSize: "14px" }}>{t("label-await-cdf")}</p>
                )}
              </SparkTableCell>
              <SparkTableCell
                style={{
                  ...cellStyle,
                  flex: "0.8",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {rowItem.attachmentMTR ? (
                  <SparkIcon
                    icName="paperclip"
                    className="modal-icon"
                    noPadding
                    pallete={hovered === rowItem.id ? "secondary" : undefined}
                  />
                ) : (
                  <p style={{ fontSize: "14px" }}>{t("label-waiting")}</p>
                )}
              </SparkTableCell>
              {(id || profile === "scrap-generator") && (
                <SparkTableCell
                  style={{
                    ...cellStyle,
                    flex: "0.8",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {rowItem.hasCDF ? (
                    <SparkIcon
                      icName="checkmark-frame"
                      pallete="secondary"
                      noPadding
                    />
                  ) : (
                    <button
                      disabled={!rowItem.attachmentMTR}
                      className="button blue"
                      onClick={(e) => {
                        if (
                          setMtrID &&
                          setMaterial &&
                          setCdfModal &&
                          setTransitionData
                        ) {
                          e.stopPropagation();
                          setTransitionData({
                            generatorId: rowItem.generatorId,
                            contractedDealerId: rowItem.contractedDealerId,
                            scrapGeneratorName: rowItem.scrapGeneratorName,
                            scrapDealerName: rowItem.scrapDealerName,
                            date: rowItem.date
                          });
                          setMtrID(rowItem.id);
                          setMaterial({
                            id: rowItem.scrapMaterial,
                            name: rowItem.scrapMaterialName,
                            quantity: rowItem.quantity,
                            unity: rowItem.unity
                          });
                          setCdfModal(true);
                        }
                      }}
                    >
                      <SparkIcon icName="add" pallete="primary" noPadding />
                    </button>
                  )}
                </SparkTableCell>
              )}
              <SparkTableCell style={{ ...cellStyle, flex: "1" }}>
                {t(`collect-statusENUM.${rowItem.status}`)}
              </SparkTableCell>
            </SparkTableRow>
          ))}
        </SparkTableBody>
      </SparkTable>
    </div>
  );
};

export default MTRSection;
