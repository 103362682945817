import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAlert } from "../../../../../contexts/alert-context";
import {
  addNewPartnerRequirementDealerTotal,
  getById,
  getPartnerRequirementbyIdDealer,
} from "../partner-requirement-check-service";

import { CountryDTO } from "../../../../../components/CountryInputAutocomplete/models/country-DTO";
import { PartnerRequirementDTO } from "../../../../settings/components/partners-requirements/partner-requirement-DTO";
import { approvalStatusENUM } from "../../../../../assets/enums/approval-status-enum";
import {
  PartnerRequirementDealerDTO,
  PartnerRequirementDealerTotalDTO,
} from "../partner-requirement-check-DTO";
import Expiration from "../../../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import { addDaysToToday } from "../../../../../services/date-format";
import {
  SparkIcon,
  SparkTableBody,
  SparkTableCell,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { extractSubstringFile } from "../../../../../services/string-treatment";

interface ModalProps {
  onClose: () => void;
  requirementId: string;
}

const PartnerRequirementForm: React.FC<ModalProps> = ({
  onClose,
  requirementId,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [partnerRequirementDTO, setPartnerRequirementDTO] =
    useState<PartnerRequirementDTO | null>(null);
  const [partnerRequirementDealerDTO, setPartnerRequirementDealerDTO] =
    useState<PartnerRequirementDealerDTO | null>(null);

  const [isCheckedUpload, setIsCheckedUpload] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO?.documentUploadRequired ??
      false
  );
  const [isCheckedApproval, setIsCheckedApproval] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO
      ?.documentApprovalRequired ?? false
  );
  const [isCheckedExpiration, setIsCheckedExpiration] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO?.hasExpiration ?? false
  );
  const [valueDaysExpiration, setValueDaysExpiration] = useState<number>(0);
  const [valueDaysWarning, setValueDaysWarning] = useState<number>(0);
  const [unitDaysExpiration, setUnitDaysExpiration] = useState(1);
  const [unitDaysWarning, setUnitDaysWarning] = useState(1);
  const renderOptions = [
    { display: `${t("days")}`, value: 1 },
    { display: `${t("weeks")}`, value: 7 },
    { display: `${t("months")}`, value: 30 },
    { display: `${t("years")}`, value: 365 },
  ];

  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    hasExpirationDate: false,
    docName: "",
    expirationDate: "",
    warningPeriod: 0,
    solved: false,
    origin: "",
  });

  const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setValue(
      "partnerRequirementInfoDTO.expirationPeriod",
      valueDaysExpiration * unitDaysExpiration,
      {
        shouldValidate: true,
      }
    );

    setValue(
      "partnerRequirementInfoDTO.expirationWarning",
      valueDaysWarning * unitDaysWarning,
      {
        shouldValidate: true,
      }
    );
  }, [
    valueDaysExpiration,
    unitDaysExpiration,
    valueDaysWarning,
    unitDaysWarning,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PartnerRequirementDealerTotalDTO>();

  useEffect(() => {
    if (requirementId) {
      getById(requirementId).then(async (data) => {
        if (data) {
          setPartnerRequirementDTO(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof PartnerRequirementDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof PartnerRequirementDTO, value as any);
            }
          });
          const dealerData = await getPartnerRequirementbyIdDealer(
            requirementId,
            id || ""
          );
          setValue("partnerRequirementDealerDTO", dealerData);
          if (dealerData == null || dealerData.expirationDocument == null) {
            if (data.partnerRequirementInfoDTO.hasExpiration) {
              setIsCheckedExpiration(
                data.partnerRequirementInfoDTO.hasExpiration
              );
              setValueDaysWarning(
                data.partnerRequirementInfoDTO.expirationWarning
              );
              setValueDaysExpiration(
                data.partnerRequirementInfoDTO.expirationPeriod
              );
              setExpiration({
                ...expiration,
                docName: data.partnerRequirementInfoDTO.requirement,
                hasExpirationDate: true,
                expirationDate: addDaysToToday(
                  data.partnerRequirementInfoDTO.expirationPeriod
                ),
                warningPeriod: data.partnerRequirementInfoDTO.expirationWarning,
                originId: id || "",
              });
            }
          } else {
            setPartnerRequirementDealerDTO(dealerData);
            setExpiration(dealerData.expirationDocument);
            setIsCheckedExpiration(dealerData.hasExpiration);
          }

          setIsCheckedApproval(
            data.partnerRequirementInfoDTO.documentApprovalRequired
          );
          setIsCheckedUpload(
            data.partnerRequirementInfoDTO.documentUploadRequired
          );
          setValue(
            "partnerRequirementInfoDTO.country",
            data.partnerRequirementInfoDTO.country[0]
          );
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (documentDealer: PartnerRequirementDealerTotalDTO) => {
    try {
      documentDealer.partnerRequirementDealerDTO.dealerId = id || "";
      documentDealer.partnerRequirementDealerDTO.requirementId =
        requirementId || "";
      documentDealer.partnerRequirementDealerDTO.requirement =
        documentDealer.partnerRequirementInfoDTO.requirement;
      documentDealer.partnerRequirementDealerDTO.hasExpiration =
        expiration.hasExpirationDate;
      if (expiration.hasExpirationDate) {
        if (expiration.expirationDate.length === 0) {
          return setAlert({
            text: `${t("alert-error-date-empty")}`,
            type: "error",
          });
        } else {
          documentDealer.partnerRequirementDealerDTO.expirationDocument =
            expiration;
        }
      }

      if (id) {
        await addNewPartnerRequirementDealerTotal(
          documentDealer,
          selectedImages
        );
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-date-empty")}:`, type: "error" });
    }
  };
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryDTO | null>(null);

  const handleCountryChange = (country: CountryDTO) => {
    setSelectedCountryDetails(country);

    setValue("partnerRequirementInfoDTO.country", country.country, {
      shouldValidate: true,
    });
  };
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];
    setSelectedImages(files);

    const previewsArray = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          }
        };
      });
    });

    Promise.all(previewsArray).then((previews) => {
      setPreviews(previews);
    });
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>
            {partnerRequirementDTO?.partnerRequirementInfoDTO.requirement}
          </h2>
        </div>
        <br />

        {isCheckedUpload && (
          <div className="flex-column">
            <h4>{t("label-document-upload-required")}</h4>
        
            <div className="input-and-preview">
              <input
                type="file"
                id="imageUpload"
                accept="*"
                multiple
                onChange={handleImageChange}
              />
            </div>
          </div>
        )}

        {partnerRequirementDealerDTO?.files?.length &&
          partnerRequirementDealerDTO.files.length > 0 && (
            <div className="flex-column">
              <div className="custom-line-hr" />
              <h4>
                <strong>{t("label-documents")}</strong>
              </h4>
              <SparkTableBody>
                {partnerRequirementDealerDTO?.files.map(
                  (rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                      onClick={() => window.open(rowItem, "_blank")}
                    >
                      <SparkTableCell
                        style={{ color: "var(--bosch-accent-blue)" }}
                      >
                        {extractSubstringFile(rowItem)}
                      </SparkTableCell>
                      <SparkTableCell className="icon-column">
                        <SparkIcon
                          pallete="tertiary"
                          className="icon-flag"
                          no-padding={true}
                          icName={"upload"}
                        />
                      </SparkTableCell>
                    </SparkTableRow>
                  )
                )}
              </SparkTableBody>
            </div>
          )}

        <div className="custom-line-hr" />
        {isCheckedApproval && (
          <div className="flex-column">
            <h4>{t("label-document-approval-demanded")}</h4>
            <br />
            <p>{t("label-whish-to-approve")}</p>

            <div className="flex-column">
              <select
                id="accountType"
                {...register("partnerRequirementDealerDTO.sdmApprovalStatus", {
                  required: true,
                })}
              >
                <option value=""></option>
                {Object.entries(approvalStatusENUM).map(([key, value]) => (
                  <option key={key} value={key}>
                    {t(`approvalStatusENUM.${value}`)}
                  </option>
                ))}
              </select>
              {errors.partnerRequirementDealerDTO?.sdmApprovalStatus && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
          </div>
        )}

        {isCheckedExpiration && (
          <Expiration
            dto={expiration}
            dataHandler={handleDataChange}
            expirationDate={"expirationDate"}
            warningPeriod={"warningPeriod"}
            showPreviousWarning
            hasExpirationDate={"hasExpirationDate"}
            hasExpirationDateInit={isCheckedExpiration}
            expirationDateInit={
              expiration.expirationDate || addDaysToToday(valueDaysExpiration)
            }
            warningPeriodInit={expiration.warningPeriod || valueDaysWarning}
          />
        )}

        <div className="custom-line-hr" />
        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations" style={{ paddingBottom: 10 }}>
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartnerRequirementForm;
