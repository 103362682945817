export const COLLECTIONS = {
  USERS: "Users",
  EMAIL_WARNING: "EmailsWarning",
  SCRAP_DEALERS: "ScrapDealers",
  SCRAP_GENERATORS: "ScrapGenerators",
  COUNTRY_CONFIG: "CountryConfig",
  BANK_INFO: "BankInfo",
  ADDRESS: "Address",
  CONTACTS: "Contacts",
  OBSERVATIONS: "Observations",
  SCRAP_CATEGORIES: "ScrapCategories",
  SCRAP_GROUPS: "ScrapGroups",
  CREDIT_ANALYSIS: "CreditAnalysis",
  EXPIRATION_DOCUMENTS: "ExpirationDocuments",
  SCRAP_BY_GENERATOR: "ScrapByGenerator",
  SCRAP_BY_GENERATOR_NEGOTIATION: "ScrapByGeneratorNegotiation",
  SCRAP_BY_GENERATOR_BID: "ScrapByGeneratorBid",
  SCRAP_BY_GENERATOR_CONTRACT: "ScrapByGeneratorContract",
  SCRAP_BY_GENERATOR_INVITED_TABLE: "ScrapByGeneratorInvitedTable",
  CHANGELOG: "Changelog",
  PARTNER_REQUIREMENTS: "PartnerRequirements",
  PARTNER_REQUIREMENTS_DEALER: "PartnerRequirementsDealer",
  PARTNER_REQUIREMENTS_DEALER_TOTAL: "PartnerRequirementFullfilled",
  SCRAP_CATEGORY_TOTAL: "ScrapCategoryTotal",
  CONTAINERS_TYPE: "ContainersType",
  BANKS: "Banks",
  APPROVE_ROLL: "AproveRoll",
  IN_BILLING_CREDIT: "InBillingCredit",
  PENDING_APPROVAL: "PendingApproval",
  SPECIAL_TECHNOLOGY: "SpecialTechnology",
  SOCIAL_ACTIVITY: "SocialActivity",
  COLLECTS: "ScrapByGeneratorCollects",
  FINANCIAL: "Financial",
  DEALERS_PRIVATE_BID: "DealersPrivateBid",
  VEHICLES: "Vehicles",
  DRIVERS: "Drivers",
  CDF: "ScrapByGeneratorCdf",
  CADRI: "ScrapByGeneratorCadri",
  MTR_CATEGORY: "MTRCategory",
  MTR_CATEGORY_CLASS: "MTRCategoryClass",
  MTR_UNITY: "MTRUnity",
  MTR_PHISIC_STATE: "MTRPhisicState",
  OPTIMIZATIONS: "Optimizations",
  FEE: "Fee",
};
