import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";
import FilterComponent from "../../components/FIlter/filter";
import { VehiclesInfoDTO } from "./vehicles-DTO";
import VehicleForm from "./vehicle-form/vehicle-form";
import { getVehiclesById } from "./vehicles-service";
import { SparkTable, SparkTableBody, SparkTableCell, SparkTableHead, SparkTableRow } from "@bosch-web-dds/spark-ui-react";

// Funções de ordenação
const sortByVehicleName = (data: VehiclesInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.licensePlate?.toString().toLowerCase() || '';
    const nameB = b.licensePlate?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};

const Vehicles: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehicles, setVehicles] = useState<VehiclesInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<VehiclesInfoDTO[]>([]);
  
  const data = { originId: id };

  const sortOptions = [
    { label: t("label-name"), sortFunction: sortByVehicleName },
  ];

  const handleFilterClick = (filteredData: VehiclesInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: VehiclesInfoDTO[]) => VehiclesInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]); // Ordenar os dados filtrados
    setFilteredData(sortedData);
  };

  const fetchVehicles = async () => {
    if (id) {
      try {
        const data = await getVehiclesById(id);
        setVehicles(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching vehicles: ", error);
      }
    }
  };

  const [vehicleId, setVehicleId] = useState<string | null>(null);

  useEffect(() => {
    fetchVehicles();
  }, [id]);

  const handleOpenModal = (vehicleId: string | undefined) => {
    setVehicleId(vehicleId ?? "")
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchVehicles(); // Atualizar a lista de contatos ao fechar o modal
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <VehicleForm vehicleId={vehicleId ?? ""} onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={()=>
              handleOpenModal("")}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi="exportvehiclescsv"
              importApi="importvehiclescsv"
              data={data}
              onClose={handleCloseModal}
            />
            <FilterComponent
              data={vehicles}
              onFilterClick={handleFilterClick}
              sortOptions={sortOptions}
            />
          </div>
          <br></br>
          <SparkTable>
          <SparkTableHead>
            
            <SparkTableRow>
              <SparkTableCell><strong>{t("label-license-plate")}</strong></SparkTableCell>
              <SparkTableCell><strong>{t("label-type")}</strong></SparkTableCell>
            </SparkTableRow>
          </SparkTableHead>
          <SparkTableBody> 
          {filteredData.map((obj:any, rowIndex:any) => (
            <SparkTableRow
              onClick={() => {
                      handleOpenModal(obj.id);
                    }}
                    key={rowIndex}
                    >
              <SparkTableCell>{obj.licensePlate}</SparkTableCell>
              <SparkTableCell>{obj.type}</SparkTableCell>
            </SparkTableRow>
          ))}
          </SparkTableBody>
        </SparkTable>
        
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Vehicles;
