import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import {
  AddressInfoDTO,
  BankAccountInfoDTO,
  ObservationsDTO,
  ScrapDealerDTO,
  ScrapDealerInfoDTO,
} from "./scrap-dealer-DTO";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  callFunction,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";
import { ContactInfoDTO } from "../contacts/contacts-DTO";

// add new scrapdealer
export async function addNewScrapDealer(data: ScrapDealerDTO) {
  data.scrapDealerInfo.region = `${data.addressInfo.city} - ${data.addressInfo.state}`;

  if (data.id == undefined) {
    saveNewAsync(COLLECTIONS.SCRAP_DEALERS, data.scrapDealerInfo).then(
      (docRef) => {
        data.contactInfo.type = "Principal";
        data.contactInfo.originId = docRef.id;
        saveNewWithId(COLLECTIONS.ADDRESS, data.addressInfo, docRef.id);
        saveNewWithId(COLLECTIONS.BANK_INFO, data.bankAccountInfo, docRef.id);
        saveNewWithId(
          COLLECTIONS.CONTACTS,
          data.contactInfo,
          docRef.id + "contact"
        );
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.SCRAP_DEALERS, data.scrapDealerInfo, data.id);
    update(COLLECTIONS.ADDRESS, data.addressInfo, data.id);
    update(COLLECTIONS.BANK_INFO, data.bankAccountInfo, data.id);
    update(COLLECTIONS.CONTACTS, data.contactInfo, data.id + "contact");
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);

    if (data.scrapDealerInfo.isHeadquarter) {
      callFunction(
        "updateheadquartername",
        { id: data.id, name: data.scrapDealerInfo.legalName },
        data.id
      );
    }

    callFunction("updatehomologationstatus", { id: data.id }, data.id);
  }
}

// get all branchs of scrapdealers by id
export async function getBranchsScrapDealersById(
  scrapDealerId: string
): Promise<ScrapDealerInfoDTO[]> {
  const scrapDealersCollectionRef = collection(
    firestore,
    COLLECTIONS.SCRAP_DEALERS
  );
  const q = query(
    scrapDealersCollectionRef,
    where("isHeadquarter", "==", false),
    where("headquarterId", "==", scrapDealerId)
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ScrapDealerInfoDTO[];
  return items;
}

// get all scrapdealers
export async function getScrapDealers(
  profile: string,
  originId: string,
  scrapGeneratorView: boolean
): Promise<ScrapDealerInfoDTO[]> {
  let querySnapshot;
  if (profile == "admin-bosch" && !scrapGeneratorView) {
    querySnapshot = await getDocs(
      collection(firestore, COLLECTIONS.SCRAP_DEALERS)
    );
  } else {
    const q = query(
      collection(firestore, COLLECTIONS.SCRAP_DEALERS),
      where("customers", "array-contains-any", [originId])
    );

    querySnapshot = await getDocs(q);
  }
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["legalName"].concat(
        " ",
        doc.data()["nationalRegister"],
        " ",
        doc.data()["tradingName"],
        " ",
        doc.data()["region"]
      ),
  })) as unknown as ScrapDealerInfoDTO[];
  return items;
}

// get all scrapdealers
export async function getScrapDealersValidated(): Promise<
  ScrapDealerInfoDTO[]
> {
  let querySnapshot;
  const q = query(
    collection(firestore, COLLECTIONS.SCRAP_DEALERS),
    where("homologationStatus", '!=', "NOT_COMPLIANT")
  );
  querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["legalName"].concat(
        " ",
        doc.data()["nationalRegister"],
        " ",
        doc.data()["tradingName"],
        " ",
        doc.data()["region"]
      ),
  })) as unknown as ScrapDealerInfoDTO[];
  return items;
}

// get scrapdealer details
export const getScrapDealerById = async (
  id: string
): Promise<ScrapDealerDTO | null> => {
  const scrapDealerInfo = await getScrapDealerInfo(id);
  const contactInfo = await getScrapDealerContact(id);
  const addressInfo = await getScrapDealerAddress(id);
  const bankAccountInfo = await getScrapDealerBank(id);
  const observations = await getScrapDealerObservations(id);

  return {
    id,
    scrapDealerInfo,
    addressInfo,
    bankAccountInfo,
    contactInfo,
    observations,
  };
};

export async function getScrapDealerInfo(
  id: string
): Promise<ScrapDealerInfoDTO> {

  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapDealerInfoDTO;
}

export async function getScrapDealerAddress(
  id: string
): Promise<AddressInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.ADDRESS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as AddressInfoDTO;
}

export async function getScrapDealerBank(
  id: string
): Promise<BankAccountInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.BANK_INFO, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as BankAccountInfoDTO;
}

export async function getScrapDealerContact(
  id: string
): Promise<ContactInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CONTACTS, id + "contact");
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ContactInfoDTO;
}

export async function getScrapDealerObservations(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
