import React, { useState } from 'react';
import './tooltip.css'; // Certifique-se de que este CSS esteja configurado corretamente

interface TooltipProps {
    text: string;
    children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div 
            className="tooltip-container" 
            onMouseEnter={showTooltip} 
            onMouseLeave={hideTooltip}
        >
            {children}
            {visible && <div className="tooltip">{text}</div>}
        </div>
    );
};

export default Tooltip;
