import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { VehiclesInfoDTO, VehiclesDTO, ObservationsDTO } from "./vehicles-DTO";
import {
  saveFiles,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";

// add new
export async function addNewVehicle(
  data: VehiclesDTO,
  id: string,
  files: File[]
) {
  if (data.id == undefined) {
    data.vehiclesInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.VEHICLES, data.vehiclesInfoDTO).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (files.length > 0) {
        saveFiles(
          COLLECTIONS.VEHICLES,
          docRef.id,
          COLLECTIONS.VEHICLES,
          files,
          "files"
        );
      }
    });
  } else {
    update(COLLECTIONS.VEHICLES, data.vehiclesInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    if (files.length > 0) {
      saveFiles(
        COLLECTIONS.VEHICLES,
        data.id,
        COLLECTIONS.VEHICLES,
        files,
        "files"
      );
    }
  }
}

export async function getVehiclesById(id: string): Promise<VehiclesInfoDTO[]> {
  const vehiclesCollectionRef = collection(firestore, COLLECTIONS.VEHICLES);

  const q = query(vehiclesCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc.data()["licensePlate"].concat(" ", doc.data()["type"]),
  })) as unknown as VehiclesInfoDTO[];
  return items;
}

export const getVehicleById = async (
  id: string
): Promise<VehiclesDTO | null> => {
  const vehiclesInfoDTO = await getVehicle(id);
  const observations = await getVehicleObservation(id);

  return {
    id,
    vehiclesInfoDTO,
    observations,
  };
};

export async function getVehiclesByGeneratorAndDealer(
  dealerId: string,
  generatorId: string
): Promise<VehiclesInfoDTO[]> {
  const vehiclesCollectionRef = collection(firestore, COLLECTIONS.VEHICLES);

  const q = query(
    vehiclesCollectionRef,
    where("originId", "==", dealerId),
    where("customers", "array-contains-any", [generatorId])
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as VehiclesInfoDTO[];

  return items;
}

export async function getVehicle(id: string): Promise<VehiclesInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.VEHICLES, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as VehiclesInfoDTO;
}

export async function getVehicleObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
